import { createSelector } from "reselect";

import {
    OramaSearchResults,
    PersistValue,
    SchemaSortBy,
} from "@magoot-sdk/declarations/general/filter";

import { KEY_FILTER_MY_ITEMS } from "../../constants/general";
import { TStore } from "../reducers/declarations";
import { IGeoForm } from "../reducers/reducerTerritorial/declarations";
import { TReducerUI } from "../reducers/reducerUI/declarations";

export const selectorUi = (store: TStore): TReducerUI | undefined => store.ui;

// #region ::: TOKEN
export const selectorIsRefreshingToken = (store: TStore): boolean =>
    store.ui?.isRefreshingToken || false;
// #endregion

// #region ::: APP
export const selectorIsAppReady = (store: TStore): boolean => store.appReady;
// #endregion

// #region ::: LOGIN
export const selectorErrorsLogin = (store: TStore): string => store.ui?.loginErrors || "";
// #endregion

// #region ::: SIGNUP
export const selectorErrorsSignup = (store: TStore): string => store.ui?.signupErrors || "";

export const selectorHasSignupSuccess = (store: TStore): boolean =>
    !!store.ui?.hasSignedUp || false;

export const selectoriIsSigning = (store: TStore): boolean => !!store.ui?.isSigning || false;
// #endregion

// #region ::: CHANGE PASSWORD
export const selectorIsChangingPassword = (store: TStore): boolean =>
    !!store.ui?.isChangingPassword || false;

export const selectorPasswordChangeErrors = (store: TStore): string =>
    store.ui?.passwordChangeErrors || "";
// #endregion

// #region ::: RESET PASSWORD
export const selectorErrorsResetPassword = (store: TStore): string =>
    store.ui?.resetPasswordError || "";

export const selectorIsResettingPassword = (store: TStore): boolean =>
    store.ui?.isResettingPassword || false;

export const selectorHasResetPassword = (store: TStore): boolean =>
    store.ui?.hasResetPassword || false;
// #endregion

// #region ::: FORGOT PASSWORD
export const selectorIsSendingForgotPassword = (store: TStore): boolean =>
    store.ui?.isSendingForgtPassword || false;

export const selectorForgotPasswordErrors = (store: TStore): string =>
    store.ui?.forgotPasswordErrors || "";

export const selectorHasEmailForgotPasswordSent = (store: TStore): boolean =>
    store.ui?.hasEmailForgotPassword || false;
// #endregion

// #region ::: INVITE USER
export const selectorIsInvitingUser = (store: TStore): boolean => store.ui?.isInvitingUser || false;

export const selectorInviteUserErrors = (store: TStore): string => store.ui?.inviteUserErrors || "";
// #endregion

// #region ::: JOIN STUDIO
export const selectorHasInviteStudioAccepted = (store: TStore): boolean =>
    store.ui?.hasInviteStudioAccepted || false;
// #endregion

// #region ::: PROFILE
export const selectorHasActivatedAccount = (store: TStore): boolean =>
    store.auth?.hasActivatedAccount || false;

export const selectorIsUpdatingProfile = (store: TStore): boolean =>
    store.ui?.isUpdatingProfile || false;

export const selectorHasStudioImgUpdated = (store: TStore): boolean =>
    store.ui?.hasStudioImgUpdated || false;
// #endregion

// #region ::: STUDIO
export const selectorStudioIsUpdating = (store: TStore): boolean =>
    store.ui?.isUpdatingStudio || false;

export const selectorIsCreatingStudio = (store: TStore): boolean =>
    store.ui?.isCreatingStudio || false;
// #endregion

// #region ::: ORDER
export const selectorHasCreatedOrder = (store: TStore): boolean =>
    !!store.ui?.orderCreated || false;

export const selectorOrderCreated = (store: TStore): number | undefined => store.ui?.orderCreated;

export const selectorIsCreatingOrder = (store: TStore): boolean =>
    store.ui?.isCreatingOrder || false;

export const selectorIsUpdatingOrder = (store: TStore): boolean =>
    store.ui?.isUpdatingOrder || false;

export const selectorIsUpdatingOrderTeam = (store: TStore): boolean =>
    store.ui?.isUpdatingOrderTeam || false;

// #endregion

// #region ::: UPLOAD
export const selectorUploadingInfo = (store: TStore): any => {
    if (!store.ui) return null;
    if (!store.ui.uploadingInfo) return null;
    return store.ui.uploadingInfo;
};
// #endregion

// #region ::: FILTERS
export const selectorOramaSearchCustomer = (store: TStore): OramaSearchResults | undefined => {
    if (!store.ui) return undefined;
    if (!store.ui.filter) return undefined;
    if (!store.ui.filter.oramaSearch) return undefined;
    return store.ui.filter.oramaSearch.index === "customer"
        ? store.ui.filter.oramaSearch
        : undefined;
};

export const selectorOramaSearchNoRerender = createSelector(
    [selectorOramaSearchCustomer],
    (order) => {
        return order;
    }
);

export const selectorOramaSearchSortBy = (store: TStore): SchemaSortBy | undefined => {
    if (!store.ui) return undefined;
    if (!store.ui.filter) return undefined;
    if (!store.ui.filter.oramaSearch) return undefined;
    if (!store.ui.filter.oramaSearch.sortBy) return undefined;

    return store.ui.filter.oramaSearch.sortBy;
};

export const selectorOramaSearchSortByNoRerender = createSelector(
    [selectorOramaSearchSortBy],
    (order) => {
        return order;
    }
);

export const selectorOramaFiltersSearch = (
    store: TStore,
    index: string
): PersistValue | undefined => {
    if (!store.ui) return undefined;
    if (!store.ui.filter) return undefined;
    if (!store.ui.filter.persistSearch) return undefined;
    if (!store.ui.filter.persistSearch[index]) return undefined;

    return store.ui.filter.persistSearch[index];
};

export const selectorOramaFiltersSearchNoRerender = createSelector(
    [selectorOramaFiltersSearch],
    (order) => {
        return order;
    }
);
// #endregion

// #region ::: SUBSCRIPTION
export const selectorSubscriptionModalVisible = (store: TStore): boolean =>
    store.ui?.showSubscriptionModal || false;

export const selectorSubscriptionModalSource = (store: TStore): string | undefined =>
    store.ui?.source;
// #endregion

// #region ::: IDEA
export const selectorIsCreatingIdea = (store: TStore): boolean => store.ui?.isCreatingIdea || false;
// #endregion

// #region ::: PAPERWORK
export const selectorIsImportingPaperwork = (store: TStore): boolean =>
    store.ui?.isImportingPaperwork || false;
// #endregion

// #region ::: DOCUMENT WORK
export const selectorIsCreatingDocuments = (store: TStore): boolean => {
    if (!store.ui?.isCreatingDocuments) return false;
    const isCreatingDocumentsToArray = Object.values(store.ui?.isCreatingDocuments);
    const isCreatingDocument = isCreatingDocumentsToArray.some((value) => value);

    return isCreatingDocument;
};
export const selectorIsDeleteDocuments = (store: TStore): boolean => {
    if (!store.ui?.isDeleteDocuments) return false;
    const isDeleteDocumentsToArray = Object.values(store.ui?.isDeleteDocuments);
    const isDeleteDocument = isDeleteDocumentsToArray.some((value) => value);

    return isDeleteDocument;
};
// #endregion

// #region ::: UPLOAD
export const selectorIsUploadingFileStudio = (store: TStore): boolean =>
    store.ui?.isUploadingFile.studio || false;

export const selectorIsUploadingFileProfile = (store: TStore): boolean =>
    store.ui?.isUploadingFile.profile || false;

export const selectorIsUploadingFileCustomer = ({
    store,
    idEntity,
}: {
    store: TStore;
    idEntity: number;
}): boolean => {
    if (!store.ui?.isUploadingFile.customers) return false;
    if (!store.ui?.isUploadingFile.customers[idEntity]) return false;

    return store.ui?.isUploadingFile.customers[idEntity];
};

export const selectorIsUploadingFileInspection = ({
    store,
    idEntity,
}: {
    store: TStore;
    idEntity: number | null;
}): boolean => {
    if (!store.ui?.isUploadingFile.inspections) return false;
    if (!idEntity || !store.ui?.isUploadingFile.inspections[idEntity]) return false;

    return store.ui?.isUploadingFile.inspections[idEntity];
};

export const selectorIsUploadingFileIdea = ({
    store,
    idEntity,
}: {
    store: TStore;
    idEntity: number | null;
}): boolean => {
    if (!store.ui?.isUploadingFile.ideas) return false;
    if (!idEntity || !store.ui?.isUploadingFile.ideas[idEntity]) return false;

    return store.ui?.isUploadingFile.ideas[idEntity];
};

export const selectorIsUploadingFileDocument = (store: TStore): boolean | number => {
    if (!store.ui?.isUploadingFile.documents) return false;
    return Object.keys(store.ui?.isUploadingFile.documents).length;
};

export const selectorLoadingListDocument = (store: TStore): boolean => {
    if (!store.ui?.isLoadingDocumentList) return false;
    return store.ui?.isLoadingDocumentList;
};

export const selectorValueDeadlinesForm = (store: TStore): string =>
    store.ui?.valueSwitchDeadlines || KEY_FILTER_MY_ITEMS.toString();

export const selectorValueOrdersForm = (store: TStore): string =>
    store.ui?.valueSwitchOrders || KEY_FILTER_MY_ITEMS.toString();

export const selectorEditWorkDetail = (store: TStore): boolean => store.ui?.editWorkDetail || false;

export const selectorGeoForm = (store: TStore): IGeoForm | undefined => store.ui?.geoForm;

export const selectorRestoreExternalLink = (store: TStore): string | undefined =>
    store.ui?.restoreExternalLink;

export const selectorIsFormNotSaved = (store: TStore): boolean | undefined =>
    store.ui?.isFormNotSaved;

export const selectorCanLeaveForm = createSelector([selectorUi], (ui) => {
    return ui?.canLeaveForm;
});

export const selectorForceOpenDialog = (
    store: TStore
): { state: boolean; action: string } | undefined => store.ui?.forceOpenDialog;

export const selectorGoNewRoutes = (store: TStore): boolean | undefined => store.ui?.goNewRoutes;

export const selectorAppVersion = (store: TStore): string | undefined =>
    store.ui?.appVersion?.version;
export const selectorAppVersionIsUpdate = (store: TStore): boolean | undefined =>
    store.ui?.appVersion?.isUpdate;
// #endregion

// #region ::: DOWNLOAD
export const selectorInspectionIsDownloadingPdf = (store: TStore): boolean | undefined =>
    store.ui?.isInspectionDownloadingPdf;

export const selectorIsUploadLoadingIdea = (store: TStore): boolean | undefined =>
    store.ui?.isLoadingIdea;

export const selectorUIFilterSummary = (
    store: TStore
): "document" | "annotation" | "inspection" | "gantt" | "all" => store.ui?.filterSummary ?? "all";

// #endregion
