import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { EUrl } from "@magoot-sdk/declarations/general/urlParams";
import { actionUIGoNewRoutes } from "@magoot-sdk/redux-modules/actions/ui/actionUi";
import { selectorGoNewRoutes } from "@magoot-sdk/redux-modules/selectors/selectorsUI";

import { ID_OWNER, ID_ROLE_ADMIN } from "../constants/general";
import { useOrderDetail } from "../redux-modules/hooks/hooksOrder";
import { useTeamList } from "../redux-modules/hooks/user/useTeamList";
import { selectorUserID } from "../redux-modules/selectors/selectorsAuth";
import {
    utilityGetUrlViewAnnotationList,
    utilityGetUrlViewCommitmentList,
    utilityGetUrlViewCustomerCreate,
    utilityGetUrlViewCustomerDetail,
    utilityGetUrlViewCustomerList,
    utilityGetUrlViewDocumentList,
    utilityGetUrlViewIdeaCreate,
    utilityGetUrlViewIdeaDetail,
    utilityGetUrlViewIdeaList,
    utilityGetUrlViewInspectionList,
    utilityGetUrlViewMunicipalPractices,
    utilityGetUrlViewOperatorList,
    utilityGetUrlViewOrder,
    utilityGetUrlViewOrderSettings,
    utilityGetUrlViewPayments,
    utilityGetUrlViewSummary,
    utilityGetUrlViewTaskList,
    utilityGetUrlViewTerritorialData,
} from "../utils/Routes";

interface Params {
    idStudio: number;
    idOrder: number;
    idWork?: number;
    idCustomer?: number;
    idIdea?: number;
    locationPathname: string;
}

interface TReturn {
    onNavigateToCommimentList: () => void;
    onNavigateToOperatorList: () => void;
    onNavigateToIdeaList: () => void;
    onNavigateToWorkList: () => void;
    onNavigateToSettings: () => void;
    onNavigateToTerritorialData: () => void;
    onNavigateToSummary: () => void;
    onNavigateToPayments: () => void;
    onNavigateToActivities: () => void;
    onNavigateToDocuments: () => void;
    onNavigateToInspections: () => void;
    onNavigateToAnnotations: () => void;
    onNavigateToMunicipalPractices: () => void;
    isSummary: boolean;
    isCommitment: boolean;
    isOperator: boolean;
    isTerritorialData: boolean;
    isWork: boolean;
    isIdea: boolean;
    isPayment: boolean;
    isSetting: boolean;
    isTabActivities: boolean;
    isTabDocuments: boolean;
    isTabInspections: boolean;
    isTabAnnotations: boolean;
    isTabMunicipalPractices: boolean;
    isNewRoutes: boolean | undefined;
}

export const useToolbarNavigation = ({
    idStudio,
    idOrder,
    idWork,
    idCustomer,
    idIdea,
    locationPathname,
}: Params): TReturn => {
    const dispatch = useDispatch();
    const isNewRoutes = useSelector(selectorGoNewRoutes);
    const history = useHistory();
    const userId = useSelector(selectorUserID);
    const { order } = useOrderDetail();
    const { teamList } = useTeamList({ reload: false });
    const { tabSelected, category }: any = useParams();
    const isTabActivities = tabSelected === "attivita";
    const isTabDocuments = tabSelected === "documenti" && !category;
    const isTabInspections = tabSelected === "sopralluoghi";
    const isTabAnnotations = tabSelected === "annotazioni";
    const isTabMunicipalPractices = tabSelected === "praticaComunale";
    const isTabWork =
        isTabActivities ||
        isTabDocuments ||
        isTabInspections ||
        isTabAnnotations ||
        isTabMunicipalPractices;

    const accessoCostiPersonale = useMemo(() => {
        const currentUser = teamList.find((member) => member.id === userId);
        const currentOrderUser = order?.utenti
            ? order?.utenti?.find((member) => member.id === userId)
            : undefined;
        if (currentUser && !currentOrderUser?.accessoCostiPersonale && idStudio !== 616) {
            return currentUser.ruolo === ID_OWNER || currentUser.ruolo === ID_ROLE_ADMIN;
        }

        if (currentOrderUser) {
            return currentOrderUser.accessoCostiPersonale;
        }

        return false;
    }, [order, teamList]);

    // #region ::: LINKS
    const linkCommitmentList = utilityGetUrlViewCommitmentList({
        idOrder,
        idStudio,
    });
    const linkCommitmentCreate = utilityGetUrlViewCustomerCreate({
        idStudio,
        idOrder,
        category: EUrl.Committenti,
        tabSelected: "generale",
    });
    const linkOperatorCreate = utilityGetUrlViewCustomerCreate({
        idStudio,
        idOrder,
        category: EUrl.Operatori,
        tabSelected: "generale",
    });

    const linkCommitmentDetailContact = utilityGetUrlViewCustomerDetail({
        idStudio,
        idOrder,
        idCustomer: idCustomer || 0,
        category: EUrl.Committenti,
        tabSelected: "generale",
    });
    const linkCommitmentDetailDocument = utilityGetUrlViewCustomerDetail({
        idStudio,
        idOrder,
        idCustomer: idCustomer || 0,
        category: EUrl.Committenti,
        tabSelected: "documenti",
    });
    const linkCommitmentCustomerList = utilityGetUrlViewCustomerList({
        idStudio,
        idOrder,
        category: EUrl.Committenti,
    });
    const linkOperatorList = utilityGetUrlViewOperatorList({
        idOrder,
        idStudio,
    });

    const linkOperatorDetailContact = utilityGetUrlViewCustomerDetail({
        idStudio,
        idOrder,
        idCustomer: idCustomer || 0,
        category: EUrl.Operatori,
        tabSelected: "generale",
    });
    const linkOperatorDetailDocument = utilityGetUrlViewCustomerDetail({
        idStudio,
        idOrder,
        idCustomer: idCustomer || 0,
        category: EUrl.Operatori,
        tabSelected: "documenti",
    });
    const linkOperatorCustomerList = utilityGetUrlViewCustomerList({
        idStudio,
        idOrder,
        category: EUrl.Operatori,
    });
    const linkIdeaList = utilityGetUrlViewIdeaList({
        idOrder,
        idStudio,
    });
    const linkIdeaCreate = utilityGetUrlViewIdeaCreate({
        idOrder,
        idStudio,
    });
    const linkIdeaDetail = utilityGetUrlViewIdeaDetail({
        idOrder,
        idIdea: idIdea || 0,
        idStudio,
    });
    const linkWorkList = utilityGetUrlViewOrder({
        idOrder,
        idStudio,
    });
    const linkSettingGeneral = utilityGetUrlViewOrderSettings({
        idOrder,
        tabSelected: "generale",
        idStudio,
    });
    const linkSettingTeam = utilityGetUrlViewOrderSettings({
        idOrder,
        tabSelected: "team",
        idStudio,
    });
    const linkTerritorialData = utilityGetUrlViewTerritorialData({
        idOrder,
        idStudio,
    });
    const linkSummary = utilityGetUrlViewSummary({
        idOrder,
        idStudio,
    });
    const linkPaymentsPrevision = utilityGetUrlViewPayments({
        idOrder,
        idStudio,
        tabSelected: "preventivo",
    });
    const linkPaymentsEarn = utilityGetUrlViewPayments({
        idOrder,
        idStudio,
        tabSelected: "incasso",
    });
    const linkPaymentsCost = utilityGetUrlViewPayments({
        idOrder,
        idStudio,
        tabSelected: "spese",
    });
    const linkActivities = utilityGetUrlViewTaskList({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    const linkDocuments = utilityGetUrlViewDocumentList({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    const linkMunicipalPractices = utilityGetUrlViewMunicipalPractices({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    const linkInspections = utilityGetUrlViewInspectionList({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    const linkAnnotations = utilityGetUrlViewAnnotationList({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    // #endregion

    // #region ::: LOCATIONS
    const isSummary = locationPathname === linkSummary;
    const isCommitment =
        locationPathname === linkCommitmentList ||
        locationPathname === linkCommitmentCreate ||
        locationPathname === linkCommitmentDetailContact ||
        locationPathname === linkCommitmentDetailDocument ||
        locationPathname === linkCommitmentCustomerList;

    const isOperator =
        locationPathname === linkOperatorList ||
        locationPathname === linkOperatorCreate ||
        locationPathname === linkOperatorDetailContact ||
        locationPathname === linkOperatorDetailDocument ||
        locationPathname === linkOperatorCustomerList;
    const isTerritorialData = locationPathname === linkTerritorialData;
    const isWork = locationPathname === linkWorkList || isTabWork;
    const isIdea =
        locationPathname === linkIdeaList ||
        locationPathname === linkIdeaCreate ||
        locationPathname === linkIdeaDetail;
    const isPayment =
        locationPathname === linkPaymentsPrevision ||
        locationPathname === linkPaymentsEarn ||
        locationPathname === linkPaymentsCost;
    const isSetting =
        locationPathname === linkSettingGeneral || locationPathname === linkSettingTeam;
    // #endregion

    // #region ::: ON NAVIGATE
    const onNavigateToCommimentList = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkCommitmentList);
    };
    const onNavigateToOperatorList = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkOperatorList);
    };
    const onNavigateToIdeaList = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkIdeaList);
    };
    const onNavigateToWorkList = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkWorkList);
    };
    const onNavigateToSettings = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkSettingGeneral);
    };
    const onNavigateToTerritorialData = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkTerritorialData);
    };
    const onNavigateToSummary = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkSummary);
    };
    const onNavigateToPayments = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(accessoCostiPersonale ? linkPaymentsPrevision : linkPaymentsCost);
    };
    const onNavigateToActivities = (): void => history.push(linkActivities);
    const onNavigateToDocuments = () => history.push(linkDocuments);
    const onNavigateToInspections = () => history.push(linkInspections);
    const onNavigateToAnnotations = () => history.push(linkAnnotations);
    const onNavigateToMunicipalPractices = () => history.push(linkMunicipalPractices);
    // #endregion

    useEffect(() => {
        setTimeout(() => {
            dispatch(actionUIGoNewRoutes({ state: false }));
        }, 750);
    }, [isNewRoutes]);

    return {
        onNavigateToCommimentList,
        onNavigateToOperatorList,
        onNavigateToIdeaList,
        onNavigateToWorkList,
        onNavigateToSettings,
        onNavigateToTerritorialData,
        onNavigateToSummary,
        onNavigateToPayments,
        onNavigateToActivities,
        onNavigateToDocuments,
        onNavigateToInspections,
        onNavigateToMunicipalPractices,
        onNavigateToAnnotations,
        isSummary,
        isCommitment,
        isOperator,
        isTerritorialData,
        isWork,
        isIdea,
        isPayment,
        isSetting,
        isTabActivities,
        isTabDocuments,
        isTabInspections,
        isTabAnnotations,
        isTabMunicipalPractices,
        isNewRoutes,
    };
};
