// #region ::: IMPORT
import React, { FC, memo, useState } from "react";
import { isSafari } from "react-device-detect";
import { TButtonVariant } from "theme/maps/button/mapButtonVariants";

import { TTranslation, translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

import { UIActionBar } from "../ActionBar";
import { UIButton } from "../Button";

// #endregion

export interface Props {
    children: any;
    onClose: () => void;
    title: string | TTranslation;
    isFullscreen?: boolean;
    isSidebarModal?: boolean;
    fitContent?: boolean;
    onSave?: (params: any) => void;
    isDisabledSave?: boolean;
    isVertical?: boolean;
    labelSave?: TTranslation;
    labelDelete?: TTranslation;
    variantSave?: TButtonVariant;
    size?: "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "full";
    isLoading?: boolean;
}

export const UIModal: FC<Props> = memo(
    ({
        children,
        onClose,
        title,
        isFullscreen = false,
        isSidebarModal = false,
        fitContent = false,
        onSave,
        isDisabledSave,
        isVertical = false,
        labelSave = "button.save",
        labelDelete = "button.cancel",
        variantSave = "primary",
        size = "xl",
        isLoading,
    }: Props): JSX.Element => {
        const [animate, setAnimate] = useState<"animate-mount" | "animate-unmount">(
            "animate-mount"
        );
        const locale = useLocale();

        const justifyContent = "justify-end";

        const handleOnClose = (): void => {
            setAnimate("animate-unmount");

            onClose();
            setAnimate("animate-mount");
        };

        return (
            <div
                className={`
      modal-bottom md:modal-middle modal modal-open md:max-w-none !my-0
      ${animate === "animate-mount" ? "animate-mount" : ""} 
      ${animate === "animate-unmount" ? "animate-unmount" : ""}
      `}
            >
                <div
                    className={`modal-box overflow-visible p-0
            ${fitContent ? "max-w-auto" : ""}
            
            ${size === "md" ? "lg:max-w-md" : ""} 
            ${size === "lg" ? "lg:max-w-lg" : ""} 
            ${size === "xl" ? "lg:max-w-xl" : ""} 
            ${size === "2xl" ? "lg:max-w-2xl" : ""} 
            ${size === "3xl" ? "lg:max-w-3xl" : ""} 
            ${size === "4xl" ? "lg:max-w-4xl" : ""} 
            ${size === "5xl" ? "lg:max-w-5xl" : ""} 
            ${size === "6xl" ? "lg:max-w-6xl" : ""} 
            ${size === "7xl" ? "lg:max-w-7xl" : ""} 
            ${size === "full" ? "lg:max-w-full" : ""}
          
            ${
                isFullscreen
                    ? `flex h-screen w-screen max-w-none flex-col rounded-none md:rounded-none max-h-screen md:max-h-container`
                    : ""
            }
            
            ${
                isSidebarModal
                    ? "flex h-container max-h-screen flex-col rounded-none md:w-screen lg:h-auto lg:rounded-xl"
                    : ""
            }
          `}
                >
                    <h3 className='whitespace-normal p-16 pb-0 text-xl font-bold text-secondaryDark text-center'>
                        {title}
                    </h3>
                    <div
                        className={`
                        grow overflow-y-auto overflow-x-hidden
                        ${
                            isFullscreen
                                ? "container  mx-auto flex justify-center lg:my-20"
                                : "mt-0 p-24"
                        } 
             ${isSidebarModal ? "max-h-none" : isFullscreen ? "" : "max-h-modalContainer"}
            `}
                    >
                        {children}
                    </div>
                    <div
                        className={`
            ${isSafari ? "pr-80" : ""}
            ${
                isFullscreen
                    ? "rounded-b-lg border-t border-tundora-200 bg-tundora-100 py-16 lg:px-80 px-16"
                    : "border-t border-tundora-200 bg-tundora-100 p-16 rounded-b-none lg:rounded-b-2xl"
            }`}
                    >
                        {onSave && (
                            <UIActionBar
                                onSave={onSave}
                                labelOnSave={labelSave}
                                isDisabledSave={isDisabledSave}
                                onDelete={handleOnClose}
                                labelOnDelete={labelDelete}
                                justifyContent={justifyContent}
                                isLoading={isLoading}
                                variantSave={variantSave}
                            />
                        )}
                        {!onSave && !isVertical && (
                            <div className='modal-action mt-0 mr-60 md:mr-0 flex flex-row justify-end'>
                                <UIButton
                                    text={translate({ locale, id: "general.label.close" })}
                                    onClick={handleOnClose}
                                    dataCy='TO_DO'
                                    variant='secondary'
                                    fluid={false}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

UIModal.displayName = "UIModal";
