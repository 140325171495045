import { Actions, TError } from "../../../utils/Actions";
import {
    TActionOrderListFailure,
    TActionOrderListRequest,
    TActionOrderListSuccess,
} from "./declarations";

export const actionOrderListRequest = (payload: { idStudio: number }): TActionOrderListRequest => ({
    type: Actions.orderListRequest,
    payload,
});

export const actionOrderListSuccess = (payload: any): TActionOrderListSuccess => ({
    type: Actions.orderListSuccess,
    payload,
});

export const actionOrderListFailure = (payload: TError): TActionOrderListFailure => ({
    type: Actions.orderListFailure,
    payload,
});
