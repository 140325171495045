import { utilityCheckWithdrawalAttempts } from "../../utils/time/checkExpirationDateUTC";
import { utilityGetRemainingTimeBetweenTwoDates } from "../../utils/time/getRemainingTimeBetweenTwoDates";
import { TStore } from "../reducers/declarations";
import {
    TBillingInformation,
    TCurrentSubscription,
    TPlans,
    TStripeListPaymentMethods,
    TSubscription,
} from "../reducers/reducerSubscription/declarations";
import { TUser } from "../reducers/reducerUsers/declarations";
import { selectorWorkList } from "./selectorWork";
import { selectorOrderList } from "./selectorsOrder";

export const selectorSubscription = (store: TStore): { [idStudio: string]: TSubscription } => {
    const storeSubscription = store.subscription;

    return storeSubscription;
};

export const selectorSubscriptionCustomerId = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): string | boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;

    return store.subscription[idStudio]?.customerId;
};

export const selectorSubscriptionDeleteUsers = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TUser[] | boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;

    return store.subscription[idStudio]?.deleteUsers;
};

export const selectorSubscriptionBillingInformation = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TBillingInformation | boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;

    return store.subscription[idStudio]?.billingInformation;
};

export const selectorSubscriptionPaymentMethodIsLoading = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean | undefined => {
    if (!store.subscription) return undefined;
    if (!store.subscription[idStudio]) return undefined;

    return store.subscription[idStudio]?.listPaymentMethods?.loading;
};

export const selectorSubscriptionCurrentIsLoading = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean | undefined => {
    if (!store.subscription) return undefined;
    if (!store.subscription[idStudio]) return undefined;

    return store.subscription[idStudio]?.currentSubscription?.loading;
};

export const selectorSubscriptionPlansIsLoading = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean | undefined => {
    if (!store.subscription) return undefined;
    if (!store.subscription[idStudio]) return undefined;

    return store.subscription[idStudio]?.plans?.loading;
};

export const selectorSubscriptionListPaymentMethods = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TStripeListPaymentMethods | boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio]?.listPaymentMethods) return false;

    return store.subscription[idStudio]?.listPaymentMethods;
};

export const selectorSubscriptionQuantity = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): number | boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;

    return store.subscription[idStudio]?.quantity;
};

export const selectorSubscriptionQuantityPlan = ({
    store,
    idStudio,
    idPlan,
}: {
    store: TStore;
    idStudio: number;
    idPlan: number;
}): number | boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].plans) return false;
    if (!store.subscription[idStudio].plans.value) return false;

    return store.subscription[idStudio].plans.value[idPlan]?.quantity || 0;
};

export const selectorSubscriptionInterval = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): "year" | "month" | "day" | boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;

    return store.subscription[idStudio]?.interval;
};

export const selectorSubscriptionId = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): string | boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;

    return store.subscription[idStudio]?.currentSubscription?.idStripeSubscription;
};

export const selectorSubscriptionSelectedPlan = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TPlans | boolean | any => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio]?.plans) return false;

    const plan =
        !!store.subscription[idStudio]?.plans?.value &&
        store.subscription[idStudio]?.plans?.value.find((value) => value.selected);

    return !!plan && plan;
};

export const selectorSubscriptionCurrentSubscription = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TCurrentSubscription | boolean | any => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;

    return store.subscription[idStudio]?.currentSubscription;
};

export const selectorSubscriptionCurrentSubscriptionUser = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TCurrentSubscription | boolean | any => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;
    if (!store.subscription[idStudio].currentSubscription.numeroUtenti) return false;

    return store.subscription[idStudio]?.currentSubscription.numeroUtenti;
};

export const selectorSubscriptionRetrieveUpcomingInvoice = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TCurrentSubscription | boolean | any => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;

    return store.subscription[idStudio]?.retrieveUpcomingInvoice?.invoice;
};

export const selectorSubscriptionRetrieveUpcomingInvoiceBalance = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TCurrentSubscription | boolean | any => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;

    return store.subscription[idStudio]?.retrieveUpcomingInvoice?.invoiceBalance;
};

export const selectorSubscriptionRetrieveUpcomingInvoiceTotalAmount = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TCurrentSubscription | boolean | any => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;

    return store.subscription[idStudio]?.retrieveUpcomingInvoice?.invoiceTotalAmount;
};

export const selectorSubscriptionTrial = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;

    return store.subscription[idStudio]?.currentSubscription?.trial;
};

export const selectorSubscriptionBalance = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): number | boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;
    return (
        store.subscription[idStudio]?.currentSubscription?.abbonamento?.latest_invoice
            ?.ending_balance || false
    );
};

export const selectorSubscriptionLoading = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;
    return store.subscription[idStudio].currentSubscription.loading;
};

export const selectorIsStudioSubscribed = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;
    if (!store.subscription[idStudio].currentSubscription.id) return false;

    return true;
};

export const selectorIsStudioCanceled = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;

    return !!store.subscription[idStudio]?.currentSubscription?.dataDisdetta;
};

export const selectorSubscriptionExpired = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;

    return store.subscription[idStudio]?.currentSubscription?.annullato;
};

export const selectorSubscriptionCanceled = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;

    return !!store.subscription[idStudio]?.currentSubscription?.dataDisdetta;
};

export const selectorSubscriptionWithdrawalAttempts = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;
    const lastDay = utilityCheckWithdrawalAttempts(
        store.subscription[idStudio]?.currentSubscription?.dataScadenza
    );

    return lastDay;
};

export const selectorSubscriptionExpiring = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;
    const remainingTime = utilityGetRemainingTimeBetweenTwoDates(
        new Date(store.subscription[idStudio]?.currentSubscription?.dataScadenza),
        new Date()
    );
    const { days, hours, minutes } = remainingTime;
    const hasHoursOrMinutes = hours > 0 || minutes > 0;

    if (days === 10 && hasHoursOrMinutes) return false;
    if (days > 0 && days <= 10) return true;
    if (days === 0 && hasHoursOrMinutes) return true;
    if (days < 0 && hours < 0 && minutes < 0) return false;

    return false;
};

export const selectorPermissionOrders = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio?: number;
}): boolean => {
    if (!idStudio) return false;
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;
    const orderList = selectorOrderList(store) || [];

    return (
        orderList.length <
        store.subscription[idStudio]?.currentSubscription?.funzionalita.COM?.limite
    );
};

export const selectorPermissionWorks = ({
    store,
    idStudio,
    idOrder,
}: {
    store: TStore;
    idStudio: number;
    idOrder?: number;
}): boolean => {
    if (!idOrder) return false;
    if (!store.subscription) return false;
    if (!store.subscription[idStudio]) return false;
    if (!store.subscription[idStudio].currentSubscription) return false;
    const orderList = selectorWorkList({ store, idOrder }) || [];

    return (
        orderList.length <
        store.subscription[idStudio]?.currentSubscription?.funzionalita.LAV?.limite
    );
};
