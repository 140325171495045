/* eslint-disable react/no-unknown-property */
// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { ChangeEvent, FC, memo } from "react";

import { TTranslation } from "@magoot-sdk/i18n/translate";

import { UIIcon } from "@components-ui/Icon";

// #endregion

export interface Props {
    min?: number;
    max?: number;
    placeholder: string;
    label?: TTranslation;
    dataCy: string;
    value?: string | number;
    type?: string;
    disabled?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    maxLength?: number;
    uppercase?: boolean;
    valid?: boolean;
    isLoading?: boolean;
    isRequired?: boolean;
    info?: string;
    prefix?: string | undefined;
    onFocus?: () => void;
    onBlur?: (event: React.FocusEvent<any>) => void;
    onSubmit?: (event: ChangeEvent<HTMLInputElement>) => void;
    shouldBlockNavigation?: boolean;
    fluid?: boolean;
    hasFocus: boolean;
    textCenter?: boolean;
    height?: number;
    fieldName?: string;
    size?: "sm" | "md" | "lg";
    icon?: IconProp;
    onClickIcon?: () => void;
}

export const UIInputDefault: FC<Props> = memo(
    ({
        placeholder,
        dataCy,
        value = "",
        disabled,
        onChange,
        maxLength,
        min,
        max,
        valid = true,
        prefix,
        uppercase = false,
        onFocus,
        onBlur,
        fluid = true,
        type,
        textCenter = false,
        size = "md",
        fieldName,
        icon,
        onClickIcon,
    }): JSX.Element => (
        <div className={`${icon ? "input-group" : ""}`}>
            <input
                className={`input input-bordered focus:outline-offset-negativeSmall focus:outline-secondaryLite
        ${uppercase ? "text-uppercase" : ""} 
        ${textCenter ? "text-center" : "text-left"} 
        ${!valid ? "border-error" : ""} 
        ${fluid ? "w-full" : ""}
        ${size === "sm" ? `input-sm` : ""} 
        ${size === "md" ? `input-md` : ""} 
        ${size === "lg" ? `input-lg` : ""} 
      `}
                type={type}
                data-cy={dataCy}
                value={value || ""}
                placeholder={placeholder}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                maxLength={maxLength}
                max={max}
                min={min}
                prefix={prefix}
                name={fieldName}
                autoComplete={type === "password" ? "on" : "off"}
            />

            {!!icon && (
                <div
                    className={`
        btn btn-square bg-tundora-100 border-l-0 hover:border-tundora-200 border-tundora-200 hover:bg-tundora-200
        ${size === "sm" ? `btn-sm` : ""} 
        ${size === "md" ? `btn-md` : ""}  
        ${size === "lg" ? `btn-lg` : ""}
        `}
                    onClick={onClickIcon}
                >
                    <UIIcon svgIcon={icon} />
                </div>
            )}
        </div>
    )
);

UIInputDefault.displayName = "UIInputDefault";
