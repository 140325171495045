import { Actions } from "../../utils/Actions";
import { TExpiredList, TReducerExpiredListActions } from "./declarations";

const initialState: TExpiredList = {
    inScadenza: {},
    scaduti: {},
    neiTempi: {},
    loading: false,
    salvataggioMultiplo: [],
};

export const reducerExpiredList = (
    prevStore: TExpiredList = initialState,
    action: TReducerExpiredListActions
): any => {
    switch (action.type) {
        case Actions.expiredListRequest:
            return { loading: true };
        case Actions.expiredListSuccess:
            return { ...action.payload, loading: false, salvataggioMultiplo: [] };
        case Actions.expiredListFailure:
            return { loading: false, salvataggioMultiplo: [] };
        case Actions.actionMultipleExpiredList:
            const oldSalvataggio = prevStore.salvataggioMultiplo.find(
                (s) => s.idAttivita === action.payload.attivita[0].idAttivita
            );
            let newSalvataggio = action.payload.attivita[0];

            if (oldSalvataggio) {
                newSalvataggio = {
                    ...oldSalvataggio,
                    idStatoEsecuzione: action.payload.attivita[0].idStatoEsecuzione,
                };
            }

            return {
                ...prevStore,
                salvataggioMultiplo: prevStore.salvataggioMultiplo
                    .filter(
                        (filterSM) => filterSM.idAttivita !== action.payload.attivita[0].idAttivita
                    )
                    .concat(newSalvataggio),
            };
        case Actions.createLogoutSuccess:
            return {
                inScadenza: {},
                scaduti: {},
                neiTempi: {},
                loading: false,
                salvataggioMultiplo: [],
            };
        default:
            return prevStore;
    }
};
