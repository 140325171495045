// #region ::: IMPORT
import avatarPlaceholder from "assets/avatar-placeholder.png";
import React, { FC, SyntheticEvent, memo, useState } from "react";

import { apiNotificationDelete } from "@magoot-sdk/api/notifications/notificationRead";
import { translate } from "@magoot-sdk/i18n/translate";
import { actionShowToast } from "@magoot-sdk/redux-modules/actions/general/actionToast";
import { actionNotificationReadRequest } from "@magoot-sdk/redux-modules/actions/notifications/actionNotificationRead";
import { actionNotificationDecrement } from "@magoot-sdk/redux-modules/actions/notifications/actionNotificationReceive";
import { actionOrderListRequest } from "@magoot-sdk/redux-modules/actions/order/actionOrderList";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { TNotification } from "@magoot-sdk/redux-modules/reducers/reducerNotifications/declarations";
import { useDispatch } from "@magoot-sdk/redux-modules/utils/useDispatch";
import { utilityGetUrlEndPoint } from "@magoot-sdk/utils/helpers/getUrlEndPoint";
import { utilityFormatDate, utilityGmtIntoUtcFormatHour } from "@magoot-sdk/utils/time/formatDate";

import { UILoader } from "@components-ui/Loader";
import { NavigateTo } from "@components-ui/NavigateTo";

// #endregion

interface Props {
    onClickOutside: () => void;
    notification: TNotification;
    to: string;
}

export const Notification: FC<Props> = memo(({ notification, to, onClickOutside }): JSX.Element => {
    const [isCancel, setIsCancel] = useState(false);
    const locale = useLocale();
    const dispatch = useDispatch();
    const { idStudio } = useStudioID();
    const date = utilityFormatDate(notification?.dataNotifica || new Date().toString());
    const time = utilityGmtIntoUtcFormatHour(
        notification?.dataNotifica.toString() || new Date().toString()
    );

    const isError = (message: string): void => {
        setIsCancel(false);

        dispatch(
            actionShowToast({
                message,
                category: "error",
                intlFormatter: true,
            })
        );
    };

    const srcImage = notification?.user?.srcImage
        ? `${utilityGetUrlEndPoint()}${notification?.user?.srcImage}?${Date.now()}`
        : avatarPlaceholder;

    const reloadNotification = (): void => {
        setIsCancel(false);

        dispatch(
            actionNotificationDecrement({
                idStudio: notification.idStudio,
                idNotification: notification.id,
            })
        );

        dispatch(
            actionShowToast({
                message: "button.delete-success",
                category: "success",
                intlFormatter: true,
            })
        );
    };

    const onCancel = async (event: SyntheticEvent): Promise<void> => {
        event.stopPropagation();
        setIsCancel(true);

        await apiNotificationDelete({
            idStudio,
            notification: notification.id ? [notification.id] : [],
        })
            .then((resp) => {
                if (resp.statuscode !== 200) isError(resp?.error?.message);
                if (resp.statuscode === 200) reloadNotification();
            })
            .catch((resp) => isError(resp?.error?.message));
    };

    const onGoNotification = (): void => {
        dispatch(
            actionNotificationReadRequest({
                idStudio: notification.idStudio,
                notification: notification.id ? [notification.id] : [],
            })
        );

        dispatch(
            actionNotificationDecrement({
                idStudio: notification.idStudio,
                idNotification: notification.id,
            })
        );
        dispatch(actionOrderListRequest({ idStudio }));

        if (to) onClickOutside();
    };

    const getOrder = (!!notification.messaggio && notification.messaggio.split("-")) || [];

    return (
        <>
            {!isCancel ? (
                <NavigateTo to={`/${to}`} dataCy='TO_BE_FIXED' onClick={onGoNotification}>
                    <div className='flex w-full cursor-pointer items-start space-x-12 py-12 px-8 hover:rounded hover:bg-magootGray'>
                        <div className='avatar'>
                            <div className='h-48 w-48 rounded-full border-2'>
                                <img src={srcImage} alt='utente notifica' />
                            </div>
                        </div>
                        <div className='flex-1'>
                            <p
                                className='text-sm'
                                dangerouslySetInnerHTML={{ __html: getOrder[0] }}
                            />
                            <p
                                className='text-sm'
                                dangerouslySetInnerHTML={{ __html: getOrder[1] }}
                            />

                            <div className='mt-8 flex items-center justify-between text-xs'>
                                <p>{`${date}, ${time}`}</p>
                                <p
                                    className='link-hover link-secondary cursor-pointer'
                                    onClick={onCancel}
                                >
                                    {translate({ locale, id: "button.delete-notification" })}
                                </p>
                            </div>
                        </div>
                    </div>
                </NavigateTo>
            ) : (
                <UILoader />
            )}
        </>
    );
});
Notification.displayName = "Notification";
