import { useSelector } from "react-redux";

import {
    ORDER_PERMISSION_ADMIN,
    ORDER_PERMISSION_EMPTY,
    ORDER_PERMISSION_READ,
    OrderStatus,
    TOrderPermission,
} from "../../../constants/general";
import { TStore } from "../../reducers/declarations";
import { selectorUserID } from "../../selectors/selectorsAuth";
import { useOrderID } from "../useOrderID";

// #region ::: SELECTORS
const selectorOrderPermission = (store: TStore, idOrder: number): TOrderPermission => {
    if (!store.orders) return ORDER_PERMISSION_EMPTY;
    if (!store.orders[idOrder]) return ORDER_PERMISSION_EMPTY;
    return store.orders[idOrder].diritto;
};

export const selectorOrderStatus = (store: TStore, idOrder: number): OrderStatus => {
    if (!store.orders) return OrderStatus.DaFare;
    if (!store.orders[idOrder]) return OrderStatus.DaFare;
    if (!store.orders[idOrder].statoEsecuzione) return OrderStatus.DaFare;
    if (!store.orders[idOrder].statoEsecuzione?.id) return OrderStatus.DaFare;

    return store.orders[idOrder].statoEsecuzione.id;
};

const selectorOrderEndByUserID = (store: TStore, idOrder: number): number | false => {
    if (!store.orders || !store.orders[idOrder]) return false;

    return store.orders[idOrder].idUtenteChiamante;
};
// #endregion

export const useOrderPermission = (): {
    hasValidPermission: boolean;
    hasPermissionToEndOrder: boolean;
    hasPaymentPermission: boolean;
    isVisibleAlert: boolean;
} => {
    const { idOrder } = useOrderID();

    const orderPermission = useSelector((store: TStore) => selectorOrderPermission(store, idOrder));
    const orderStatus = useSelector((store: TStore) => selectorOrderStatus(store, idOrder));
    const orderEndByUserID = useSelector((store: TStore) =>
        selectorOrderEndByUserID(store, idOrder)
    );
    const userID = useSelector(selectorUserID);

    const hasValidPermission =
        orderPermission !== ORDER_PERMISSION_READ &&
        orderPermission !== ORDER_PERMISSION_EMPTY &&
        orderStatus !== OrderStatus.Completata &&
        orderStatus !== OrderStatus.Annullata;
    const isVisibleAlert = !hasValidPermission && !Number.isNaN(idOrder);
    const hasPermissionToEndOrder =
        orderPermission === ORDER_PERMISSION_ADMIN || orderEndByUserID === userID;

    const hasPaymentPermission = orderPermission === ORDER_PERMISSION_ADMIN;

    return {
        hasValidPermission,
        isVisibleAlert,
        hasPermissionToEndOrder,
        hasPaymentPermission,
    };
};
