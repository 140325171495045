// #region ::: IMPORT
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { TOption } from "@components-ui/Select";

import { TMember } from "../../declarations/general/operatorType";
import { actionOrderDeleteRequest } from "../actions/order/actionOrderDelete";
import { actionOrderListRequest } from "../actions/order/actionOrderList";
import { actionOrderTeamRequest } from "../actions/order/actionOrderTeam";
import { actionOrderUpdateRequest } from "../actions/order/actionOrderUpdate";
import { actionUserListRequest } from "../actions/user/actionUserList";
import { TStore } from "../reducers/declarations";
import { TOrder, TOrderBase, TOrderUser } from "../reducers/reducerOrder/declarations";
import { selectorOrderSelected } from "../selectors/selectorOrder";
import { selectorOrderList, selectorOrderListLoading } from "../selectors/selectorsOrder";
import { selectorIsUpdatingOrder } from "../selectors/selectorsUI";
import { getUsers } from "../selectors/selectorsUser";
import { useDispatch } from "../utils/useDispatch";
import { useOrderID } from "./useOrderID";
import { useStudioID } from "./useStudioID";

// #endregion

// #region ::: HOOK USE ORDER LIST
export const useOrderList = (): {
    orderList: TOrder[];
    isLoading: boolean;
} => {
    const { idStudio } = useStudioID();
    const dispatch = useDispatch();
    const orderList = useSelector(selectorOrderList);
    const isLoading = useSelector(selectorOrderListLoading);

    useEffect(() => {
        if (orderList.length === 0 || !orderList) dispatch(actionOrderListRequest({ idStudio }));
    }, [dispatch, idStudio]);

    return {
        orderList,
        isLoading,
    };
};

export const useCurrentOrderList = (): {
    filterListWithActiveUser: any;
    filterListWithActiveUserOptions: TOption[];
    isLoading: boolean;
} => {
    const { idOrder } = useOrderID();
    const orderList = useSelector(selectorOrderList);
    const isLoading = useSelector(selectorOrderListLoading);
    const teamNameList = useSelector(getUsers);

    const filterList = orderList.find((order) => order.id === idOrder);
    const activeUser: TOrderUser[] | undefined = useMemo(() => {
        return filterList?.utenti.map((user) => {
            if (teamNameList[user.id]?.attivo) return teamNameList[user.id];
            return user;
        });
    }, [filterList, teamNameList]);
    const filterActiveUser = activeUser?.filter((value) => !!value) ?? [];

    const filterListWithActiveUser = {
        ...filterList,
        utenti: filterActiveUser,
    };

    const filterListWithActiveUserOptions: TOption[] = (filterListWithActiveUser.utenti ?? []).map(
        (utente) => ({
            value: utente.id,
            name: `${utente.nome}${utente.cognome ? ` ${utente.cognome}` : ""}`,
        })
    );

    return {
        filterListWithActiveUser,
        filterListWithActiveUserOptions,
        isLoading,
    };
};

export const useCurrentOrderListWritePermission = ({
    reload,
}: {
    reload: boolean;
}): {
    filterListWritePermission: any | undefined;
    isLoading: boolean;
} => {
    const { idStudio } = useStudioID();
    const { idOrder } = useOrderID();
    const dispatch = useDispatch();
    const orderList = useSelector(selectorOrderList);
    const isLoading = useSelector(selectorOrderListLoading);
    const teamNameList = useSelector(getUsers);

    const filterList = orderList.find((order) => order.id === idOrder);
    const writePermission = filterList?.utenti.filter((user) => user.diritto !== 1);
    const activeUser = useMemo(() => {
        return writePermission?.map((user) => {
            if (teamNameList[user.id]?.attivo) return teamNameList[user.id];

            return user;
        });
    }, [writePermission, teamNameList]);
    const filterActiveUser = activeUser?.filter((value) => !!value);

    const filterListWritePermission = {
        ...filterList,
        utenti: filterActiveUser,
    };

    useEffect(() => {
        if (reload) dispatch(actionUserListRequest({ idStudio }));
    }, [dispatch, idStudio, reload]);

    return {
        filterListWritePermission,
        isLoading,
    };
};
// #endregion

// #region ::: HOOK USE ORDER DETAIL
export interface TParamsDeleteOrder {
    idStudioDeleteOrder: number;
    orderDeleteOrder: TOrder;
}

export const useOrderDetail = (): {
    order: TOrder;
    idStudio: number;
    onUpdateOrder: ({ orderUpdate }: { orderUpdate: TOrderBase }) => void;
    onDeleteOrder: ({ idStudioDeleteOrder, orderDeleteOrder }: TParamsDeleteOrder) => void;
    onUpdateTeam: (team: TMember[]) => void;
    isLoadingOrder: boolean;
} => {
    const { idStudio } = useStudioID();
    const { idOrder } = useOrderID();

    const dispatch = useDispatch();
    const order = useSelector((store: TStore) => selectorOrderSelected({ store, idOrder }));
    const isUpdating = useSelector(selectorIsUpdatingOrder);

    const onDeleteOrder = ({ idStudioDeleteOrder, orderDeleteOrder }: TParamsDeleteOrder): void =>
        dispatch(
            actionOrderDeleteRequest({ idStudio: idStudioDeleteOrder, order: orderDeleteOrder })
        );

    const onUpdateOrder = ({ orderUpdate }: { orderUpdate: TOrderBase }): void => {
        dispatch(actionOrderUpdateRequest({ idStudio, order: orderUpdate, idOrder }));
    };

    const onUpdateTeam = (team: TMember[]): void =>
        dispatch(actionOrderTeamRequest({ idStudio, idOrder, team }));

    return {
        order,
        onUpdateOrder,
        onDeleteOrder,
        onUpdateTeam,
        idStudio,
        isLoadingOrder: isUpdating,
    };
};
// #endregion
