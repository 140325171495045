import { IGeoForm } from "@magoot-sdk/redux-modules/reducers/reducerTerritorial/declarations";

import { Actions } from "../../../utils/Actions";
import {
    TActionReseOrderCreated,
    TActionResetActivatedAccount,
    TActionResetActivationErrors,
    TActionResetEmailForgotPasswordSent,
    TActionResetForgotPasswordError,
    TActionResetInvitationAccepted,
    TActionResetInvitationSent,
    TActionResetLoginError,
    TActionResetSignupError,
    TActionResetSignupRequestSent,
    TActionUIAppVersion,
    TActionUIEditWorkDetail,
    TActionUIFilterSummary,
    TActionUIFormSwitchDeadlines,
    TActionUIGeoForm,
    TActionUIGeoFormReset,
    TActionUIGoNewRoutes,
    TActionUIRestoreExternalLink,
    TActionUIToggleGlobalNotify,
    TActionUiCanLeaveForm,
    TActionUiForceOpenDialog,
    TActionUiIsFormNotSaved,
    TActionUiSubscriptionModalClose,
    TActionUiSubscriptionModalOpen,
    TactionUIDeleteUploadInfo,
    TactionUISetUploadInfo,
} from "./declarations";

export const actionResetActivatedAccount = (): TActionResetActivatedAccount => ({
    type: Actions.resetActivatedAccount,
});

export const actionResetActivationErrors = (): TActionResetActivationErrors => ({
    type: Actions.resetActivationErrors,
});

export const actionResetForgotPasswordError = (): TActionResetForgotPasswordError => ({
    type: Actions.resetForgotPasswordError,
});

export const actionResetEmailForgotPasswordSent = (): TActionResetEmailForgotPasswordSent => ({
    type: Actions.resetEmailForgotPasswordSent,
});

export const actionResetInvitationAccepted = (): TActionResetInvitationAccepted => ({
    type: Actions.resetInvitationRequestAccepted,
});

export const actionResetLoginError = (): TActionResetLoginError => ({
    type: Actions.resetLoginError,
});

export const actionResetSignupError = (): TActionResetSignupError => ({
    type: Actions.resetSignupError,
});

export const actionResetSignupRequestSent = (): TActionResetSignupRequestSent => ({
    type: Actions.resetSignupRequestSent,
});

export const actionReseOrderCreated = (): TActionReseOrderCreated => ({
    type: Actions.resetOrderCreated,
});

export const actionResetInvitationSent = (): TActionResetInvitationSent => ({
    type: Actions.resetInvitationSent,
});

export const actionUISetUploadInfo = (payload: any): TactionUISetUploadInfo => ({
    type: Actions.uiSetUploadInfo,
    payload,
});

export const actionUIDeleteUploadInfo = (): TactionUIDeleteUploadInfo => ({
    type: Actions.uiDeleteUploadInfo,
});

export const actionUISetIncomingDocument = (): any => ({
    type: Actions.uiSetIncomingDocument,
});

export const actionUIDeleteIncomingDocument = (): any => ({
    type: Actions.uiDeleteIncomingDocument,
});

export const actionUISubscriptionModalOpen = (
    limit?: number,
    source?: string
): TActionUiSubscriptionModalOpen => ({
    type: Actions.uiSubscriptionModalOpen,
    payload: {
        limit,
        source,
    },
});

export const actionUISubscriptionModalClose = (): TActionUiSubscriptionModalClose => ({
    type: Actions.uiSubscriptionModalClose,
});

export const actionUIToggleGlobalNotify = (toggle: boolean): TActionUIToggleGlobalNotify => ({
    type: Actions.uiToggleGlobalNotify,
    payload: { toggle },
});

export const actionUIFormSwitchDeadlines = (value: string): TActionUIFormSwitchDeadlines => ({
    type: Actions.uiFormSwitchDeadlines,
    payload: { value },
});

export const actionUIEditWorkDetail = (value: boolean): TActionUIEditWorkDetail => ({
    type: Actions.uiEditWorkDetail,
    payload: { value },
});

export const actionUIGeoForm = (value: IGeoForm): TActionUIGeoForm => ({
    type: Actions.uiGeoForm,
    payload: { ...value },
});

export const actionUIRestoreExternalLink = (link: string): TActionUIRestoreExternalLink => ({
    type: Actions.restoreExternalLink,
    payload: {
        link,
    },
});

export const actionUIAppVersion = (version: string, isUpdate: boolean): TActionUIAppVersion => ({
    type: Actions.uiAppVersion,
    payload: {
        version,
        isUpdate,
    },
});

export const actionUIGeoFormReset = (): TActionUIGeoFormReset => ({
    type: Actions.uiGeoFormReset,
    payload: {},
});

export const actionUiIsFormNotSaved = (isFormNotSaved: boolean): TActionUiIsFormNotSaved => ({
    type: Actions.uiIsFormNotSaved,
    payload: { isFormNotSaved },
});

export const actionUiCanLeaveForm = (canLeave: boolean): TActionUiCanLeaveForm => ({
    type: Actions.uiCanLeaveForm,
    payload: { canLeave },
});

export const actionUiForceOpenDialog = ({
    state,
    action,
}: {
    state: boolean;
    action?: string;
}): TActionUiForceOpenDialog => ({
    type: Actions.uiForceOpenDialog,
    payload: { state, action },
});

export const actionUIGoNewRoutes = ({ state }: { state: boolean }): TActionUIGoNewRoutes => ({
    type: Actions.uiGoNewRoutes,
    payload: {
        state,
    },
});

export const actionUIFilterSummary = ({
    state,
}: {
    state: "document" | "annotation" | "inspection" | "gantt" | "all";
}): TActionUIFilterSummary => ({
    type: Actions.uiFilterSummary,
    payload: {
        state,
    },
});
