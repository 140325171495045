import { Actions } from "@magoot-sdk/redux-modules/utils/Actions";

import { TReducerSubscription, TReducerSubscriptionActions } from "./declarations";

export const reducerSubscription = (
    prevStore: TReducerSubscription = {},
    action: TReducerSubscriptionActions
): TReducerSubscription => {
    const newStore: TReducerSubscription = { ...prevStore };

    switch (action.type) {
        case Actions.studioSubscriptionSuccess:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                currentSubscription: action.payload.subscription,
            };
            return newStore;
        case Actions.sessionExpired:
        case Actions.createLogoutSuccess:
            return {};
        case Actions.studioSubscriptionCustomerSuccess:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                customerId: action.payload.customerId,
            };
            return newStore;
        case Actions.subscriptionBillingInformationSuccess:
        case Actions.subscriptionUpdateBillingInformationSuccess:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                billingInformation: action.payload,
            };
            return newStore;
        case Actions.subscriptionPlansSuccess:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                plans: {
                    value: action.payload?.result,
                },
            };
            return newStore;

        case Actions.studioSubscriptionRetrieveUpcomingInvoiceSuccess:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                retrieveUpcomingInvoice: action.payload.retrieveUpcomingInvoice,
            };
            return newStore;

        case Actions.subscriptionListPaymentMethodSuccess:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                listPaymentMethods: action.payload?.result,
            };
            return newStore;
        case Actions.subscriptionPlansSelected:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                plans: {
                    value: newStore[action.payload.idStudio]?.plans.value.map((plan) =>
                        plan.stripeProductID === action.payload.idPlan
                            ? { ...plan, selected: true }
                            : { ...plan, selected: false }
                    ),
                },
            };
            return newStore;
        case Actions.studioSubscriptionDeliteUsers:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                deleteUsers: action.payload.users,
            };
            return newStore;
        case Actions.studioSubscriptionClearDeliteUsers:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                deleteUsers:
                    (!!newStore[action.payload.idStudio]?.deleteUsers &&
                        newStore[action.payload.idStudio]?.deleteUsers.filter(
                            (user) => user.id !== action.payload.user
                        )) ||
                    [],
            };
            return newStore;
        case Actions.studioSubscriptionDeliteAllUsers:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                deleteUsers: [],
            };
            return newStore;
        case Actions.subscriptionQuantityAndInterval:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                quantity: action.payload.quantity || newStore[action.payload.idStudio]?.quantity,
                interval: action.payload.interval || newStore[action.payload.idStudio]?.interval,
            };
            return newStore;
        case Actions.subscriptionPlanQuantityAndInterval:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                plans: {
                    value: newStore[action.payload.idStudio]?.plans.value.map((plan) =>
                        plan.id === action.payload.idPlan
                            ? {
                                  ...plan,
                                  quantity:
                                      action.payload.quantity ||
                                      newStore[action.payload.idStudio]?.quantity,
                                  interval:
                                      action.payload.interval ||
                                      newStore[action.payload.idStudio]?.interval,
                              }
                            : { ...plan }
                    ),
                },
            };
            return newStore;
        case Actions.paymentMethodLoading:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                listPaymentMethods: {
                    ...newStore[action.payload.idStudio]?.listPaymentMethods,
                    loading: action.payload.loading,
                },
            };
            return newStore;

        case Actions.studioSubscriptionCustomerCreateRequest:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                currentSubscription: {
                    ...newStore[action.payload.idStudio]?.currentSubscription,
                    loading: true,
                },
            };
            return newStore;
        case Actions.currentSubscriptionLoading:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                currentSubscription: {
                    ...newStore[action.payload.idStudio]?.currentSubscription,
                    loading: action.payload.loading,
                },
            };
            return newStore;

        case Actions.plansLoading:
            newStore[action.payload.idStudio] = {
                ...newStore[action.payload.idStudio],
                plans: {
                    ...newStore[action.payload.idStudio]?.plans,
                    loading: action.payload.loading,
                },
            };
            return newStore;
        default:
            return prevStore;
    }
};
