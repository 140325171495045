interface Translations {
    "popup.logout.title": string;
    "popup.logout.body": string;
    "popup.logout.subscription.not-found.title": string;
    "popup.logout.subscription.not-found.body": string;
    "popup.subscription.title": string;
    "popup.subscription.body": string;
    "popup.paperwork.title": string;
    "popup.paperwork.spinner.text": string;
    "popup.paperwork.body": string;
    "popup.paperwork.export.title": string;
    "popup.paperwork.export.body": string;
    "popup.order.delete.title": string;
    "popup.order.delete.body": string;
    "popup.work.delete.title": string;
    "popup.work.delete.body": string;
    "popup.work.close.title": string;
    "popup.work.close.body": string;
    "popup.work.open.title": string;
    "popup.work.open.body": string;
    "toast.message.work-deleted": string;
    "popup.user.disconnect-success": string;
    "popup.user.disconnect-error": string;
    "popup.user.disconnect-reactivate": string;
    "popup.studio.update-success": string;
    "popup.password.change-success": string;
    "toast.message.order-deleted": string;
    "toast.message.order.team-update": string;
    "toast.message.commitment-linked": string;
    "toast.message.commitment-unlinked": string;
    "popup.confirm.disconnect-commitment.title": string;
    "popup.confirm.disconnect-commitment.body": string;
    "toast.message.operator-linked": string;
    "toast.message.operator-unlinked": string;
    "popup.confirm.disconnect-operator.title": string;
    "popup.confirm.disconnect-operator.body": string;
    "popup.confirm.delete-task.title": string;
    "popup.confirm.delete-task.body": string;
    "popup.confirm.delete-contact.title": string;
    "popup.confirm.delete-contact.body": string;
    "popup.confirm.delete-contacts.title": string;
    "popup.confirm.delete-contacts.body": string;
    "popup.confirm.delete-notes.title": string;
    "popup.confirm.delete-notes.body": string;
    "popup.confirm.update-contacts.title": string;
    "popup.confirm.update-contacts.body": string;
    "toast.message.contact-deleted": string;
    "popup.confirm.delete-document.title": string;
    "popup.confirm.delete-documents.title": string;
    "popup.confirm.delete-document.body": string;
    "popup.confirm.delete-documents.body": string;
    "toast.message.document-deleted": string;
    "toast.message.annotation-created": string;
    "toast.message.customer-updated": string;
    "toast.message.annotation-deleted": string;
    "toast.message.delete-task.success": string;
    "toast.message.inspection-created": string;
    "toast.message.inspection-deleted": string;
    "toast.message.inspection-partecipant": string;
    "toast.message.document-work-created": string;
    "toast.message.document-update": string;
    "toast.message.annotation-updated": string;
    "toast.message.inspection-edited": string;
    "toast.message.inspection-remark-edited": string;
    "toast.message.inspection-remark-deleted": string;
    "toast.message.inspection-signature-uploaded": string;
    "toast.message.inspection-signature-deleted": string;
    "toast.message.paperwork-imported": string;
    "popup.confirm.delete-inspection.title": string;
    "popup.confirm.delete-inspection.body": string;
    "toast.message.document-work-deleted": string;
    "view.view-work.toast.saved": string;
    "toast.message.idea-created": string;
    "toast.message.idea-updated": string;
    "toast.message.idea-deleted": string;
    "popup.idea.delete.title": string;
    "popup.idea.delete.body": string;
    "popup.annotation.delete.title": string;
    "popup.annotation.delete.body": string;
    "popup.confirm.delete-account.title.not.admin": string;
    "popup.confirm.delete-account.body.not.admin": string;
    "popup.confirm.delete-account.title.admin": string;
    "popup.confirm.delete-account.body.admin": string;
    "toast.message.task-created": string;
    "toast.message.dictionary-created": string;
    "popup.confirm.delete-dictionary.title": string;
    "popup.confirm.delete-dictionary.body": string;
    "popup.confirm.delete-payment-prevision.title": string;
    "popup.confirm.delete-payment-prevision.body": string;
    "popup.confirm.delete-payment-earn.title": string;
    "popup.confirm.delete-payment-earn.body": string;
    "popup.confirm.delete-payment-cost.title": string;
    "popup.confirm.delete-payment-cost.body": string;
    "popup.confirm.delete-territorial-data.title": string;
    "popup.confirm.delete-territorial-data.body": string;
    "popup.confirm.delete-urban-tool-area.title": string;
    "popup.confirm.delete-urban-tool-area.body": string;
    "popup.confirm.delete-studio": string;
    "popup.move.dictionary.error": string;
}

interface Popup {
    it: Translations;
    en: Translations;
}

export const translationsPopup: Popup = {
    it: {
        "popup.logout.title": "Esci",
        "popup.logout.body": "Sicuro di voler uscire dall'applicazione?",
        "popup.logout.subscription.not-found.title":
            "Studio non trovato o momentaneamente non disponibile",
        "popup.logout.subscription.not-found.body": `Probabilmente sei stato eliminato dallo studio o a causa di problemi tecnici non è possibile recuperare i tuoi dati. Conferma per scollegarti e riprovare l'accesso (nel caso che l'account sia stato cancellato, per riaccedere al seguente studio dovrai essere invitato nuovamente). Se l'errore dovesse persistere contattaci pure a assistenza@magoot.it oppure tramite la chat che trovi in fondo alla pagina.`,
        "popup.subscription.title": "Abbonamento",
        "popup.subscription.body":
            "Studio non trovato o non associato, a breve verrai scollegato per effettuare un nuovo accesso",
        "popup.paperwork.title": "Importa pratica",
        "popup.paperwork.spinner.text": `Attendere, l'importazione potrebbe durare qualche minuto.`,
        "popup.paperwork.body": "Pratica già importata",
        "popup.paperwork.export.title": "Esporta pratica",
        "popup.paperwork.export.body":
            "Stiamo preparando il faldone della commessa. Non appena sarà pronto, riceverai una mail contenente il link per scaricare la commessa in formato zip. Nel frattempo, puoi continuare ad utilizzare Magoot.",
        "popup.order.delete.title": "Eliminazione Commessa",
        "popup.order.delete.body": "Sicuro di voler eliminare la commessa?",
        "popup.work.delete.title": "Eliminazione fase",
        "popup.work.delete.body": "Sicuro di voler eliminare la fase?",
        "popup.work.close.title": "Chiusura fase",
        "popup.work.close.body": "Sicuro di voler chiudere la fase?",
        "popup.work.open.title": "Apertura fase",
        "popup.work.open.body": "Sicuro di voler riaprire la fase?",
        "popup.user.disconnect-success": "Utente rimosso con successo",
        "popup.user.disconnect-error": `C'è stato un problema con la rimozione dell'utente, per favore riprova!`,
        "popup.user.disconnect-reactivate": `Utente aggiunto con successo`,
        "popup.studio.update-success": "Modifica effettuata con successo",
        "popup.password.change-success": "Password cambiata con successo",
        "toast.message.order-deleted": "Commessa eliminata con successo",
        "toast.message.order.team-update": "Team aggionato con successo",
        "toast.message.commitment-linked": "Committente creato con successo",
        "toast.message.operator-linked": "Operatore creato con successo",
        "toast.message.commitment-unlinked": "Committente eliminato con successo",
        "popup.confirm.disconnect-commitment.title": "Rimuovi committente",
        "popup.confirm.disconnect-commitment.body": "Sei sicuro di voler rimuovere il committente?",
        "toast.message.customer-updated": "Contatto aggiornato con successo",
        "toast.message.operator-unlinked": "Operatore eliminato con successo",
        "popup.confirm.disconnect-operator.title": "Rimuovi operatore",
        "popup.confirm.disconnect-operator.body": "Sei sicuro di voler rimuovere l'operatore?",
        "popup.confirm.delete-task.title": "Elimina attività",
        "popup.confirm.delete-task.body": "Sei sicuro di voler eliminare la task?",
        "popup.confirm.delete-contact.title": "Elimina contatto",
        "popup.confirm.delete-contact.body": "Sei sicuro di voler eliminare il contatto?",
        "popup.confirm.delete-contacts.title": "Elimina contatti",
        "popup.confirm.delete-contacts.body": "Sei sicuro di voler eliminare i contatti?",
        "popup.confirm.delete-notes.title": "Elimina",
        "popup.confirm.delete-notes.body": "Sei sicuro di voler eliminare la nota?",
        "popup.confirm.update-contacts.title": "Aggiornamento contatto",
        "popup.confirm.update-contacts.body":
            "Sei sicuro di voler aggiornare il contatto? Questo comporterà la sua modifica in ogni importazione.",
        "toast.message.contact-deleted": "Contatto eliminato con successo",
        "popup.confirm.delete-document.title": "Elimina documento",
        "popup.confirm.delete-documents.title": "Elimina documenti",
        "popup.confirm.delete-document.body": "Sei sicuro di voler eliminare il documento?",
        "popup.confirm.delete-documents.body": "Sei sicuro di voler eliminare i documenti?",
        "toast.message.document-deleted": "Documento eliminato con successo",
        "toast.message.annotation-created": "Annotazione creata con successo",
        "toast.message.annotation-deleted": "Annotazione eliminata con successo",
        "toast.message.delete-task.success": "Attività eliminata con successo",
        "toast.message.inspection-created": "Sopralluogo creato con successo",
        "toast.message.inspection-edited": "Sopralluogo modificato con successo",
        "toast.message.inspection-remark-edited": "Osservazione modificata con successo",
        "toast.message.inspection-deleted": "Sopralluogo eliminato con successo",
        "toast.message.inspection-signature-uploaded": "Firma caricata con successo",
        "toast.message.inspection-signature-deleted": "Firma eliminata con successo",
        "toast.message.inspection-remark-deleted": "Osservazione eliminata con successo",
        "toast.message.inspection-partecipant": "Partecipante  creato con successo",
        "toast.message.work-deleted": "Lavorazione eliminata con successo",
        "toast.message.document-work-created": "Documento creato con successo",
        "toast.message.document-update": "Documento modificato con successo",
        "toast.message.annotation-updated": "Annotazione modificata con successo",
        "toast.message.paperwork-imported": "Pratica importata con successo",
        "popup.confirm.delete-inspection.title": "Elimina sopralluogo",
        "popup.confirm.delete-inspection.body": "Sei sicuro di voler eliminare il sopralluogo?",
        "toast.message.document-work-deleted": "Documento/i eliminato/i con successo",
        "view.view-work.toast.saved": "Lavorazione modificata con successo",
        "toast.message.idea-created": "Idea creata con successo",
        "toast.message.idea-updated": "Idea modificata con successo",
        "toast.message.idea-deleted": "Idea eliminata con successo",
        "popup.idea.delete.title": "Elimina idea",
        "popup.idea.delete.body": "Sei sicuro di voler eliminare l'idea?",
        "popup.annotation.delete.title": "Elimina annotazione",
        "popup.annotation.delete.body": "Sei sicuro di voler eliminare l'annotazione?",
        "popup.confirm.delete-account.title.not.admin": "Elimina account",
        "popup.confirm.delete-account.body.not.admin":
            "Sei sicuro di voler eliminare il tuo account?",
        "popup.confirm.delete-account.title.admin": "Elimina account",
        "popup.confirm.delete-account.body.admin": `Sei sicuro di voler eliminare il tuo account? Questo comporterà anche l'eliminazione del tuo studio.`,
        "toast.message.task-created": "Attività creata con successo",
        "toast.message.dictionary-created": "Dizionario creato con successo",
        "popup.confirm.delete-dictionary.title": "Eliminazione dizionario",
        "popup.confirm.delete-dictionary.body": "Sei sicuro di voler eliminare il dizionario?",
        "popup.confirm.delete-payment-prevision.title": "Elimina previsione incasso",
        "popup.confirm.delete-payment-prevision.body":
            "Sei sicuro di voler eliminare la previsione incasso?",
        "popup.confirm.delete-payment-earn.title": "Elimina incasso",
        "popup.confirm.delete-payment-earn.body": "Sei sicuro di voler eliminare l'incasso?",
        "popup.confirm.delete-payment-cost.title": "Elimina costo",
        "popup.confirm.delete-payment-cost.body": "Sei sicuro di voler eliminare il costo?",
        "popup.confirm.delete-territorial-data.title": "Elimina catasto",
        "popup.confirm.delete-territorial-data.body": "Sei sicuro di voler eliminare il catasto?",
        "popup.confirm.delete-urban-tool-area.title": "Elimina zona strumento urbanistico",
        "popup.confirm.delete-urban-tool-area.body":
            "Sei sicuro di voler eliminare la zona strumento urbanistico?",
        "popup.confirm.delete-studio": `Prima di procedere con l'eliminazione del tuo utente, bisogno annullare l'abbonamento associato.`,
        "popup.move.dictionary.error": `C'è stato un problema con l'ordinamento, per favore riprova. Se l’errore persiste contatta assistenza@magoot.it`,
    },
    en: {
        "popup.logout.title": "Exit",
        "popup.logout.body": "Are you sure you want exit?",
        "popup.logout.subscription.not-found.title": "Abbonamento non trovato",
        "popup.logout.subscription.not-found.body":
            "Conferma per scollegarti e riprovare un nuovo accesso",
        "popup.subscription.title": "Abbonamento",
        "popup.subscription.body": "Studio non trovato o non associato a utente",
        "popup.paperwork.title": "Importa pratica",
        "popup.paperwork.spinner.text": `Attendere, l'importazione potrebbe durare qualche minuto.`,
        "popup.paperwork.body": "Pratica già importata",
        "popup.paperwork.export.title": "Esporta pratica",
        "popup.paperwork.export.body":
            "Stiamo preparando il faldone della commessa. Non appena sarà pronto, riceverai una mail contenente il link per scaricare la commessa in formato zip. Nel frattempo, puoi continuare ad utilizzare Magoot.",
        "popup.order.delete.title": "Delete Order",
        "popup.order.delete.body": "Are you sure you want delete the order?",
        "popup.work.delete.title": "Delete Work",
        "popup.work.delete.body": "Are you sure you want delete the work?",
        "popup.work.close.title": "Chiusura fase",
        "popup.work.close.body": "Sicuro di voler chiudere la fase?",
        "popup.work.open.title": "Apertura fase",
        "popup.work.open.body": "Sicuro di voler riaprire la fase?",
        "popup.user.disconnect-success": "Update succesfully",
        "popup.user.disconnect-error": `C'è stato un problema con la rimozione dell'utente, per favore riprova!`,
        "popup.user.disconnect-reactivate": `Utente aggiunto con successo`,
        "popup.studio.update-success": "Update succesfully",
        "popup.password.change-success": "Password changed successfully",
        "toast.message.order-deleted": "Order deleted successfully",
        "toast.message.order.team-update": "Team update successfully",
        "toast.message.commitment-linked": "Commitment created successfully",
        "toast.message.operator-linked": "Operator created successfully",
        "toast.message.commitment-unlinked": "Customer deleted successfully",
        "popup.confirm.disconnect-commitment.title": "Rimuovi committente",
        "popup.confirm.disconnect-commitment.body": "Are you sure you want remove commitment?",
        "toast.message.customer-updated": "Contact updated succesfully",
        "toast.message.operator-unlinked": "Operator deleted successfully",
        "popup.confirm.disconnect-operator.title": "Remove operatore",
        "popup.confirm.disconnect-operator.body": "Are you sure you want remove operator?",
        "popup.confirm.delete-task.title": "Delete task",
        "popup.confirm.delete-task.body": "Are you sure you want remove task?",
        "popup.confirm.delete-contact.title": "Delete contact",
        "popup.confirm.delete-contact.body": "Are you sure you want to delete contact?",
        "popup.confirm.delete-contacts.title": "Delete contacts",
        "popup.confirm.delete-contacts.body": "Are you sure you want to delete these contacts?",
        "popup.confirm.delete-notes.title": "Delete",
        "popup.confirm.delete-notes.body": "Are you sure you want to delete the note?",
        "popup.confirm.update-contacts.title": "Aggiornamento contatto/i",
        "popup.confirm.update-contacts.body":
            "Sei sicuro di voler aggiornare il contatto? Questo comporterà la sua modifica in ogni importazione",
        "toast.message.contact-deleted": "Contact deleted successfully",
        "popup.confirm.delete-document.title": "Delete document",
        "popup.confirm.delete-documents.title": "Delete documents",
        "popup.confirm.delete-document.body": "Are you sure you want to delete this document?",
        "popup.confirm.delete-documents.body": "Are you sure you want to delete these documents?",
        "toast.message.document-deleted": "Document deleted successfully",
        "toast.message.annotation-created": "Notes created successfully",
        "toast.message.annotation-deleted": "Notes deleted successfully",
        "toast.message.delete-task.success": "Task deleted successfully",
        "toast.message.inspection-created": "Inspection created successfully",
        "toast.message.inspection-edited": "Inspection edited successfully",
        "toast.message.inspection-remark-edited": "Remark edited successfully",
        "toast.message.inspection-deleted": "Inspection deleted successfully",
        "toast.message.inspection-remark-deleted": "Remark deleted successfully",
        "toast.message.inspection-signature-uploaded": "Signature uploaded successfully",
        "toast.message.inspection-signature-deleted": "Signature deleted successfully",
        "toast.message.inspection-partecipant": "Partecipant created successfully",
        "toast.message.work-deleted": "Work deleted successfully",
        "toast.message.document-work-created": "Document created successfully",
        "toast.message.document-update": "Document updated successfully",
        "toast.message.annotation-updated": "Notes updated successfully",
        "popup.confirm.delete-inspection.title": "Delete inspection",
        "popup.confirm.delete-inspection.body": "Are you sure you want delete the inspection?",
        "toast.message.document-work-deleted": "Document/s deleted successfully",
        "view.view-work.toast.saved": "Work updated successfully",
        "toast.message.idea-created": "Idea created successfully",
        "toast.message.idea-updated": "Idea updated successfully",
        "toast.message.idea-deleted": "Idea deleted successfully",
        "popup.idea.delete.title": "Delete idea",
        "popup.idea.delete.body": "Are you sure you want delete the idea?",
        "popup.annotation.delete.title": "Delete annotation",
        "popup.annotation.delete.body": "Are you sure you want to delete the annotation?",
        "popup.confirm.delete-account.title.not.admin": "Delete account",
        "popup.confirm.delete-account.body.not.admin":
            "Are you sure you want to delete your account?",
        "popup.confirm.delete-account.title.admin": "Elimina account",
        "popup.confirm.delete-account.body.admin": `Sei sicuro di voler eliminare il tuo account? Questo comporterà anche l'eliminazione del tuo studio.`,
        "toast.message.task-created": "Task created successfully",
        "toast.message.dictionary-created": "Dictionary create successfully",
        "toast.message.paperwork-imported": "Paperwork successfully imported",
        "popup.confirm.delete-dictionary.title": "Delete dictionary",
        "popup.confirm.delete-dictionary.body": "Are you sure you want to delete the dictionary?",
        "popup.confirm.delete-payment-prevision.title": "Delete prevision",
        "popup.confirm.delete-payment-prevision.body":
            "Are you sure you want to delete the prevision?",
        "popup.confirm.delete-payment-earn.title": "Delete takings",
        "popup.confirm.delete-payment-earn.body": "Are you sure you want to delete takings?",
        "popup.confirm.delete-payment-cost.title": "Delete cost",
        "popup.confirm.delete-payment-cost.body": "Are you sure you want to delete the cost?",
        "popup.confirm.delete-territorial-data.title": "Delete land registry",
        "popup.confirm.delete-territorial-data.body": "Are you sure you want to delete takings?",
        "popup.confirm.delete-urban-tool-area.title": "Delete urban tool area",
        "popup.confirm.delete-urban-tool-area.body":
            "Are you sure you want to delete urban tool area?",
        "popup.confirm.delete-studio": `Prima di procedere con l'eliminazione del tuo utente, bisogno annullare l'abbonamento associato.`,
        "popup.move.dictionary.error": `C'è stato un problema con l'ordinamento, per favore riprova. Se l’errore persiste contatta assistenza@magoot.it`,
    },
};

export type TTranslationsPopup = keyof Translations;
