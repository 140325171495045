import {
    Attivita,
    TExpiredList,
} from "@magoot-sdk/redux-modules/reducers/reducerExpiredList/declarations";

import { Actions, TError } from "../../utils/Actions";
import {
    TActionExpiredListFailure,
    TActionExpiredListRequest,
    TActionExpiredListSuccess,
    TActionMultipleExpiredList,
} from "./declarations";

export const actionExpiredListRequest = (payload: {
    idStudio: number;
}): TActionExpiredListRequest => ({
    type: Actions.expiredListRequest,
    payload,
});

export const actionExpiredListSuccess = (payload: TExpiredList): TActionExpiredListSuccess => ({
    type: Actions.expiredListSuccess,
    payload,
});

export const actionExpiredListFailure = (payload: TError): TActionExpiredListFailure => ({
    type: Actions.expiredListFailure,
    payload,
});

export const actionMultipleExpiredList = (payload: {
    idStudio: number;
    attivita: Attivita[];
}): TActionMultipleExpiredList => ({
    type: Actions.actionMultipleExpiredList,
    payload,
});
