export enum EUrl {
    Operatori = "operatori",
    Committenti = "committenti",
    Pagamenti = "pagamenti",
    Idea = "idea",
}

export interface TParamsViewActivation {
    idUser: number;
    token: string;
    idInvitation: string;
}

export interface TParamsViewOrder {
    idOrder: number;
    idStudio: number;
    tabSelected?: "attivita" | "riepilogo";
}

export interface TParamsViewAnnotationDetail {
    idStudio: number;
    idOrder: number;
    idWork: number;
    idAnnotation: number;
}

export interface TParamsViewTerritorialData {
    idStudio: number;
    idOrder: number;
}

export interface TParamsViewSummary {
    idStudio: number;
    idOrder: number;
}

export interface TParamsViewPayments {
    idStudio: number;
    idOrder: number;
    tabSelected: "preventivo" | "incasso" | "spese";
}

export interface TParamsViewStudioDetail {
    tabSelected: "info" | "team" | "invoice";
    idStudio: number;
}

export enum SectionDictionary {
    workTypology = "tipologia-commessa",
    work = "lavorazioni",
    order = "fasi",
    send = "mezzi-invio",
    operator = "tipologia-operatore",
    typeOfDocument = "tipi-di-documento",
    centerPayments = "centri-di-costo",
}

export enum MenuSection {
    ElencoCommesse = "elenco-commesse",
    Scadenziario = "scadenziario",
    RicercaAvanzata = "ricerca-avanzata",
    Gantt = "gantt",
    AttivitaScadenza = "attività-scadenza",
    GestioneStudio = "gestione-studio",
    Rubrica = "rubrica",
    DashboardCommesse = "dashboard-commesse",
    StatisticheCommesse = "statistiche-commesse",
    ReportEconomici = "report-economici",
    Geocalizzazione = "geocalizzazione",
}

export enum SubMenuSection {
    DatiStudio = "dati-studio",
    TeamInterno = "team-interno",
    TeamEsterno = "team-esterno",
    TeamCosti = "team-costi",
    Dizionari = "dizionari",
    GestioneNotifiche = "gestione-notifiche",
    Abbonamento = "abbonamento",
}

export enum SubscriptionSection {
    SelezionePiano = "selezione-piano",
    RimozioneUtenti = "rimozione-utenti",
    Checkout = "checkout",
    Typ = "typ",
    UpdateTyp = "update-typ",
    Errore = "errore",
}

export enum TabSection {
    ImpostazioneCommessa = "generale",
    Team = "team",
}

export const getSectionDictionary = (section: SectionDictionary): string => {
    if (section === SectionDictionary.workTypology) return SectionDictionary.workTypology;
    if (section === SectionDictionary.work) return SectionDictionary.work;
    if (section === SectionDictionary.order) return SectionDictionary.order;
    if (section === SectionDictionary.send) return SectionDictionary.send;
    if (section === SectionDictionary.operator) return SectionDictionary.operator;
    if (section === SectionDictionary.typeOfDocument) return SectionDictionary.typeOfDocument;
    if (section === SectionDictionary.centerPayments) return SectionDictionary.centerPayments;

    return "";
};

export interface TParamsViewDashboard {
    idStudio: number;
    menuSelected: MenuSection;
    subMenuSelected?: SubMenuSection;
    sectionDictionary?: SectionDictionary;
    sectionSubscription?: SubscriptionSection;
}

export interface TParamsViewResetPassword {
    idUser: number;
    token: string;
}

export interface TParamsViewWork {
    idOrder: number;
    idWork: number;
    idStudio: number;
}

export interface TParamsViewDocumentWorkDetail {
    idOrder: number;
    idWork: number;
    idStudio: number;
    idDocument: number;
}

export interface TParamsViewWorkSelected {
    idOrder: number;
    idWork: number;
    idStudio: number;
    idInspection: number;
}

export interface TParamsViewWorkTab {
    idOrder: number;
    idWork: number;
    idStudio: number;
    tabSelected: string;
    tabSubSection: string;
}

export interface TParamsViewOrderSettings {
    idOrder: number;
    tabSelected: string;
    idStudio: number;
}

export interface TParamsViewOrderCreate {
    idStudio: number;
}

export interface TParamsViewInvitation {
    idInvito: string;
}

export interface TParamsViewCommitmentList {
    idStudio: number;
    idOrder: number;
}

export interface TParamsViewIdeaList {
    idStudio: number;
    idOrder: number;
}

export interface TParamsViewIdeaCreate {
    idStudio: number;
    idOrder: number;
}

export interface TParamsViewCommitmentCreate {
    idStudio: number;
    idOrder: number;
    tabSelected: "general" | "contacts" | "documents";
}

export interface TParamsViewCommitmentDetail {
    idStudio: number;
    idOrder: number;
    idCustomer: number;
}

export interface TParamsViewOperatorList {
    idStudio: number;
    idOrder: number;
}

export interface TParamsViewOperatorListCreate {
    idStudio: number;
    idOrder: number;
}

export interface TParamsViewOperatorDetail {
    idStudio: number;
    idOrder: number;
    idOperator: number;
}

export interface TParamsViewCustomerList {
    idStudio: number;
    idOrder: number;
    category: EUrl;
}

export interface TParamsViewCustomerDetail {
    idStudio: number;
    idOrder: number;
    idCustomer: number;
    category: EUrl;
    tabSelected: "generale" | "contatti" | "documenti";
}

export interface TParamsViewCustomerCreate {
    idStudio: number;
    idOrder: number;
    category: EUrl;
    tabSelected: "generale" | "contatti" | "documenti";
}

export interface TViewAnnotationDetail {
    idOrder: number;
    idWork: number;
    idStudio: number;
    idAnnotation: number;
}

export interface TViewInspectionEdit {
    idOrder: number;
    idWork: number;
    idStudio: number;
    idInspection: number;
}

export interface TParamsViewIdeaDetail {
    idStudio: number;
    idOrder: number;
    idIdea: number;
}

export interface TViewTerritorialData {
    idStudio: number;
    idOrder: number;
}

export interface TViewSummary {
    idStudio: number;
    idOrder: number;
}

export interface TViewPayments {
    idStudio: number;
    idOrder: number;
}
