// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faAddressBook,
    faAngleDown,
    faAngleLeft,
    faChartNetwork,
    faChartPie,
    faCog,
    faList,
} from "@fortawesome/pro-solid-svg-icons";
import React, { FC, MutableRefObject, memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { MenuSection } from "@magoot-sdk/declarations/general/urlParams";
import { useNavigation } from "@magoot-sdk/hooks/useNavigation";
import { translate } from "@magoot-sdk/i18n/translate";
import { useOrderList } from "@magoot-sdk/redux-modules/hooks/hooksOrder";
import { useStudio } from "@magoot-sdk/redux-modules/hooks/hooksStudio";
import { useSubscription } from "@magoot-sdk/redux-modules/hooks/subscription/useSubscription";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import {
    selectorIsAdmin,
    selectorIsPartnerOutside,
} from "@magoot-sdk/redux-modules/selectors/selectorsAuth";
import {
    selectorSubscriptionLoading,
    selectorSubscriptionTrial,
} from "@magoot-sdk/redux-modules/selectors/selectorsSubscription";
import {
    utilityGetUrlExplicationPaperwork,
    utilityGetUrlViewDashboard,
    utilityGetUrlViewOrderCreate,
} from "@magoot-sdk/utils/Routes";
import { handleDrawerNavigation } from "@magoot-sdk/utils/components/drawer";

import { UIIcon } from "@components-ui/Icon";

import { SubMenuDictionaries } from "./partials/SubMenuRow/SubMenuDictionaries";
import { SubMenuNotifications } from "./partials/SubMenuRow/SubMenuNotifications";
import { SubMenuStudioData } from "./partials/SubMenuRow/SubMenuStudioData";
import { SubMenuSubscription } from "./partials/SubMenuRow/SubMenuSubscription";
import { SubMenuTeam } from "./partials/SubMenuRow/SubMenuTeam";
import "./sidebar.scss";

interface Prop {
    drawerRef?: MutableRefObject<HTMLInputElement | undefined>;
}

export const Sidebar: FC<Prop> = memo(({ drawerRef }): JSX.Element => {
    const { hasToUpgrade, onUpgrade } = useSubscription({ code: "ORDERS" });
    const { hasToUpgrade: hasToUpgradeReports, onUpgrade: onUpgradeReports } = useSubscription({
        code: "REPORTS",
    });

    const navigateTo = useNavigation();
    const { idStudio } = useStudioID();
    const locale = useLocale();
    const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
    const { menuSelected }: any = useParams();
    const { isOwner } = useStudio();
    const isAdmin = useSelector(selectorIsAdmin);
    const history = useHistory();
    const version = process.env.REACT_APP_VERSION;
    const isPartnerOutside = useSelector(selectorIsPartnerOutside);
    const { isOrderCreate } = useStudio();
    const { orderList } = useOrderList();

    const isMenuOrderList =
        menuSelected === MenuSection.ElencoCommesse ||
        menuSelected === MenuSection.RicercaAvanzata ||
        menuSelected === MenuSection.Gantt;
    const isMenuTaskList = menuSelected === MenuSection.Scadenziario;
    const isMenuAddressBook = menuSelected === MenuSection.Rubrica;
    const isMenuReport =
        menuSelected === MenuSection.DashboardCommesse ||
        menuSelected === MenuSection.StatisticheCommesse ||
        menuSelected === MenuSection.ReportEconomici;

    const isStudioSubscriptionLoading = useSelector((store: TStore) =>
        selectorSubscriptionLoading({ store, idStudio })
    );
    const isTrialVersion = useSelector((store: TStore) =>
        selectorSubscriptionTrial({ store, idStudio })
    );

    const linkDashboardOrderList = (): void => {
        navigateTo(
            utilityGetUrlViewDashboard({
                idStudio,
                menuSelected: MenuSection.ElencoCommesse,
            })
        );
        setIsSubMenuVisible(false);
    };

    const linkDashboardOrderTask = (): void => {
        navigateTo(
            utilityGetUrlViewDashboard({
                idStudio,
                menuSelected: MenuSection.Scadenziario,
            })
        );
        setIsSubMenuVisible(false);
    };

    const linkDashboardAddressBook = (): void => {
        if (hasToUpgrade) onUpgrade();
        else {
            navigateTo(
                utilityGetUrlViewDashboard({
                    idStudio,
                    menuSelected: MenuSection.Rubrica,
                })
            );
            setIsSubMenuVisible(false);
        }
    };

    const linkDashboardReport = (): void => {
        if (hasToUpgradeReports) onUpgradeReports("report");
        else {
            navigateTo(
                utilityGetUrlViewDashboard({
                    idStudio,
                    menuSelected: MenuSection.DashboardCommesse,
                })
            );
            setIsSubMenuVisible(false);
        }
    };

    const handleSubMenu = (): void => {
        setIsSubMenuVisible(!isSubMenuVisible);
    };

    const onClickOrderCreate = (): void | null => {
        if (hasToUpgrade) onUpgrade();

        if (!hasToUpgrade) {
            const linkViewOrderCreate = utilityGetUrlViewOrderCreate({
                idStudio,
            });
            return navigateTo(linkViewOrderCreate);
        }

        return null;
    };

    const handleClickImport = (): void => {
        history.push(utilityGetUrlExplicationPaperwork({ idStudio }));
    };

    const buttonDisabled = useMemo(() => {
        if (isTrialVersion && orderList.length === 3) return true;
        if (isPartnerOutside && isOrderCreate) return false;
        if (isPartnerOutside && !isOrderCreate) return true;
        return false;
    }, [isPartnerOutside, isOrderCreate, isTrialVersion, orderList]);

    return (
        <div className='flex flex-col magoot-sidebar !z-[100] relative h-container min-h-container w-3/4 border-r border-magootGray bg-base-100 text-tundora-500 md:w-[18rem]'>
            <div className='p-20 pt-12 border-b bg-white flex flex-col flex-none'>
                <button
                    onClick={() =>
                        handleDrawerNavigation({ navigateFn: onClickOrderCreate, drawerRef })
                    }
                    className='btn btn-secondary btn-block my-0 mb-16 text-white'
                    type='button'
                    disabled={buttonDisabled}
                >
                    {translate({ locale, id: "button.order-create" })}
                </button>

                <button
                    onClick={() =>
                        handleDrawerNavigation({ navigateFn: handleClickImport, drawerRef })
                    }
                    className='btn btn-outline btn-secondary btn-block my-0 text-white'
                    type='button'
                    disabled={buttonDisabled}
                >
                    <span>{translate({ locale, id: "general.label.paperwork-import" })}</span>
                </button>
            </div>
            <div className='grow overflow-y-auto overflow-x-hidden pb-32 '>
                <ul className='menu'>
                    <li
                        className={`flex cursor-pointer flex-row items-center border-b  ${
                            isMenuOrderList ? "text-secondary font-medium" : ""
                        } `}
                        onClick={() =>
                            handleDrawerNavigation({
                                navigateFn: linkDashboardOrderList,
                                drawerRef,
                            })
                        }
                    >
                        <div className='flex w-full p-16 hover:bg-tundora-50 items-center'>
                            <UIIcon
                                size='lg'
                                svgIcon={faList as IconProp}
                                title='visualizza commesse'
                                color={isMenuOrderList ? "#8100DC" : "rgb(121, 121, 121)"}
                            />

                            <div>
                                {translate({ locale, id: "view.dashboard.sidebar.menu.orders" })}
                            </div>
                        </div>
                    </li>
                    <li
                        className={`flex cursor-pointer flex-row items-center border-b  ${
                            isMenuTaskList ? "text-secondary font-medium" : ""
                        } `}
                        onClick={() =>
                            handleDrawerNavigation({
                                navigateFn: linkDashboardOrderTask,
                                drawerRef,
                            })
                        }
                    >
                        <div className='flex w-full p-16 hover:bg-tundora-50 items-center'>
                            <UIIcon
                                size='lg'
                                svgIcon={faChartNetwork as IconProp}
                                title='visualizza attività'
                                color={isMenuTaskList ? "#8100DC" : "rgb(121, 121, 121)"}
                            />

                            <div>
                                {translate({
                                    locale,
                                    id: "view.dashboard.sidebar.menu.orders.expired",
                                })}
                            </div>
                        </div>
                    </li>

                    {(isOwner || isAdmin) && (
                        <li
                            className={`flex ${
                                isStudioSubscriptionLoading ? "menuitem-disabled" : ""
                            } cursor-pointer flex-row items-center border-b ${
                                isMenuReport ? "text-secondary font-medium" : ""
                            } `}
                            onClick={() =>
                                handleDrawerNavigation({
                                    navigateFn: linkDashboardReport,
                                    drawerRef,
                                })
                            }
                        >
                            <div className='flex w-full p-16 hover:bg-tundora-50 items-center'>
                                <UIIcon
                                    size='lg'
                                    svgIcon={faChartPie as IconProp}
                                    title='visualizza report e analisi'
                                    color={isMenuReport ? "#8100DC" : "rgb(121, 121, 121)"}
                                />
                                <div>
                                    {translate({
                                        locale,
                                        id: "view.dashboard.sidebar.menu.report",
                                    })}
                                </div>
                            </div>
                        </li>
                    )}

                    {!isPartnerOutside && (
                        <li
                            className={`flex ${
                                isStudioSubscriptionLoading ? "menuitem-disabled" : ""
                            } cursor-pointer flex-row items-center border-b ${
                                isMenuAddressBook ? "text-secondary font-medium" : ""
                            } `}
                            onClick={() =>
                                handleDrawerNavigation({
                                    navigateFn: linkDashboardAddressBook,
                                    drawerRef,
                                })
                            }
                        >
                            <div className='flex w-full p-16 hover:bg-tundora-50 items-center'>
                                <UIIcon
                                    size='lg'
                                    svgIcon={faAddressBook as IconProp}
                                    title='visualizza rubrica'
                                    color={isMenuAddressBook ? "#8100DC" : "rgb(121, 121, 121)"}
                                />
                                <div>
                                    {translate({
                                        locale,
                                        id: "view.dashboard.sidebar.menu.address-book",
                                    })}
                                </div>
                            </div>
                        </li>
                    )}

                    {!isPartnerOutside && (
                        <li
                            className={`relative ${
                                isStudioSubscriptionLoading ? "menuitem-disabled" : ""
                            } flex cursor-pointer flex-row items-center  text-tundora-500 border-b hover:bg-tundora-50`}
                            onClick={handleSubMenu}
                        >
                            <div className='flex w-full p-16 hover:bg-tundora-50 items-center'>
                                <UIIcon
                                    size='lg'
                                    svgIcon={faCog as IconProp}
                                    title='visualizza gestione studio'
                                />

                                <div>
                                    {translate({
                                        locale,
                                        id: "view.dashboard.sidebar.menu.order-setting",
                                    })}
                                </div>

                                <div className='absolute right-4 mr-8 p-0 hover:bg-transparent transition-all'>
                                    <UIIcon
                                        size='lg'
                                        svgIcon={
                                            (!isSubMenuVisible
                                                ? faAngleLeft
                                                : faAngleDown) as IconProp
                                        }
                                        title='visualizza sotto menù'
                                    />
                                </div>
                            </div>
                        </li>
                    )}

                    {isSubMenuVisible && (
                        <li className='relative'>
                            <SubMenuStudioData drawerRef={drawerRef} />
                            <SubMenuTeam drawerRef={drawerRef} />
                            <SubMenuDictionaries drawerRef={drawerRef} />
                            <SubMenuNotifications drawerRef={drawerRef} />
                            {isOwner && <SubMenuSubscription drawerRef={drawerRef} />}
                        </li>
                    )}

                    <div className='absolute bottom-8 w-full flex items-center justify-between text-xs px-8'>
                        <span className='text-secondary font-bold'>v. {version}</span>

                        <a
                            href='https://app.magoot.it/docs/it/Privacy.html'
                            className='link link-primary'
                            rel='noreferrer'
                            target='_blank'
                            title='accesso al sito Starch srl'
                        >
                            {translate({
                                locale,
                                id: "general.information.privacy",
                            })}
                        </a>
                    </div>
                </ul>
            </div>
        </div>
    );
});

Sidebar.displayName = "Sidebar";
