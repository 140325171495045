// #region ::: IMPORT
import { UserRole } from "@components-layout/UserRole";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faKey, faSignOutAlt, faUserCircle } from "@fortawesome/pro-light-svg-icons";
import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { translate } from "@magoot-sdk/i18n/translate";
import { actionLogoutRequest } from "@magoot-sdk/redux-modules/actions/auth/actionLogout";
import { actionUserListRequest } from "@magoot-sdk/redux-modules/actions/user/actionUserList";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { selectorTeamList } from "@magoot-sdk/redux-modules/hooks/user/useTeamList";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import {
    selectorIsProfileComplete,
    selectorUserID,
} from "@magoot-sdk/redux-modules/selectors/selectorsAuth";
import {
    selectorHasConnectedStudio,
    selectorHasOwnStudio,
} from "@magoot-sdk/redux-modules/selectors/selectorsStudio";
import { utilityGetUrlViewAccount, utilityGetUrlViewProfile } from "@magoot-sdk/utils/Routes";

import { TDropdownOption } from "@components-ui/Dropdown";
import { UIIcon } from "@components-ui/Icon";

// #endregion
interface Prop {
    onClickOutside: () => void;
}

export const LinkProfile: FC<Prop> = ({ onClickOutside }): JSX.Element | null => {
    const isProfileComplete = useSelector(selectorIsProfileComplete);
    const hasConnectedStudio = useSelector(selectorHasConnectedStudio);
    const hasOwnStudio = useSelector(selectorHasOwnStudio);
    const history = useHistory();
    const dispatch = useDispatch();
    const { idStudio } = useStudioID();
    const userId = useSelector(selectorUserID);
    const teamList = useSelector(selectorTeamList);

    const authIdStudio = useSelector((store: TStore) => store.auth?.idStudio);
    const hasNoStudio = !hasOwnStudio && !hasConnectedStudio;
    const showWizard = !isProfileComplete || (isProfileComplete && hasNoStudio);
    const locale = useLocale();

    const currentUser = useMemo(
        () => teamList.find((teamMember) => teamMember.id === userId),
        [teamList]
    );

    const onNavigateToProfile = (): void => {
        onClickOutside();
        history.push(utilityGetUrlViewProfile({ idStudio: idStudio || authIdStudio || 0 }));
    };

    const onNavigateToAccount = (): void => {
        onClickOutside();
        history.push(utilityGetUrlViewAccount({ idStudio: idStudio || authIdStudio || 0 }));
    };

    const onLogout = (): void => {
        onClickOutside();
        (window as any)?.productFruits?.services?.destroy();
        dispatch(
            actionLogoutRequest({
                title: "popup.logout.title",
                message: "popup.logout.body",
            })
        );
    };

    const profileOptions: TDropdownOption[] = [
        {
            name: "general.label.profile",
            icon: faUserCircle as IconProp,
            onClick: onNavigateToProfile,
        },
        {
            name: "general.label.account",
            icon: faKey as IconProp,
            divider: true,
            onClick: onNavigateToAccount,
        },
    ];

    const logoutOption: TDropdownOption[] = [
        { name: "button.logout", icon: faSignOutAlt as IconProp, onClick: onLogout },
    ];

    const optionList: TDropdownOption[] = !showWizard
        ? [...profileOptions, ...logoutOption]
        : logoutOption;

    const renderOption = (option: TDropdownOption, index: number): JSX.Element => {
        return (
            <div
                key={index}
                className='flex cursor-pointer items-center space-x-8 px-12 py-8 text-tundora-500 hover:rounded hover:bg-magootGray'
                onClick={option.onClick}
            >
                <div>
                    <UIIcon svgIcon={option.icon as IconProp} title='visualizza notifiche' />
                </div>
                {option.name && <div>{translate({ locale, id: option.name })}</div>}
            </div>
        );
    };

    useEffect(() => {
        if (idStudio && teamList && teamList.length === 0) {
            dispatch(actionUserListRequest({ idStudio }));
        }
    }, [teamList, idStudio]);

    return (
        <>
            {isProfileComplete ? (
                <div className='menu rounded-box absolute top-[3.5rem] right-0 z-50 mx-8 w-auto overflow-hidden bg-base-100 p-2 shadow-2xl sm:right-[1rem]'>
                    {currentUser && idStudio > 0 && (
                        <div className='bg-secondary/5 border-b border-b-tundora-100 mb-12 px-8 py-2 rounded'>
                            <span className='text-sm block'>
                                {currentUser.nome} {currentUser.cognome}
                            </span>
                            <UserRole user={currentUser} />
                        </div>
                    )}
                    {optionList.map(renderOption)}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
