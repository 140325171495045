import { ID_OWNER, ID_ROLE_ADMIN, ID_ROLE_COLLABORATOR_OUTSIDE } from "../../constants/general";
import { TStore } from "../reducers/declarations";
import { TReducerAuth } from "../reducers/reducerAuth/declarations";

export const selectorUser = (store: TStore): TReducerAuth =>
    // @ts-ignore
    store.auth;

export const selectorNameUser = (store: TStore): string => store.auth?.nome || "";

export const selectorIsAuthenticated = (store: TStore): boolean => !!store.auth?.token;

export const selectorAuthIsLoading = (store: TStore): boolean | undefined => store.auth?.isLoading;

export const selectorIsProfileComplete = (store: TStore): boolean =>
    !!store.auth?.isProfileComplete || false;

export const selectorIsActivatingAccount = (store: TStore): boolean =>
    !!store.auth?.isActivatingAccount || false;

export const selectorActivatingErrors = (store: TStore): string =>
    store.auth?.activationErrors || "";

export const selectorUserID = (store: TStore): number | undefined => store.auth?.idUtente;
export const selectorIdRuolo = (store: TStore): number | undefined => store.auth?.idRuolo;

export const selectorUserEmail = (store: TStore): string => store.auth?.eMail || "";

export const selectorUserName = (store: TStore): string => store.auth?.userName || "";

export const selectorToken = (store: TStore): string => store.auth?.token || "";

export const selectorRefreshToken = (store: TStore): string => store.auth?.refreshToken || "";

export const selectorExpirationDate = (store: TStore): string | Date =>
    store.auth?.tokenExpiry || "";

export const selectorIdStudioToken = (store: TStore): number | null | undefined =>
    store.auth?.idStudio;

export const selectorUserAvatar = (store: TStore): string => store.auth?.srcImage || "";

export const selectorLastNameUser = (store: TStore): string => store.auth?.cognome || "";

export const selectorTitleUser = (store: TStore): string => store.auth?.titolo || "";

export const selectorTaxCodeUser = (store: TStore): string => store.auth?.codiceFiscale || "";

export const selectorIsLoadingUser = (store: TStore): boolean => store.auth?.isLoading || false;

export const selectorIsAdmin = (store: TStore): boolean => {
    if (!store.auth?.idRuolo) return false;
    return store.auth?.idRuolo === ID_ROLE_ADMIN;
};

export const selectorIsOwner = (store: TStore): boolean => {
    if (!store.auth?.idRuolo) return false;
    return store.auth?.idRuolo === ID_OWNER;
};

export const selectorIsPartnerOutside = (store: TStore): boolean => {
    if (!store.auth?.idRuolo) return false;
    return store.auth?.idRuolo === ID_ROLE_COLLABORATOR_OUTSIDE;
};
