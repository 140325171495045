import { differenceInDays, isEqual } from "date-fns";
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import {
    MenuSection,
    SubMenuSection,
    SubscriptionSection,
} from "@magoot-sdk/declarations/general/urlParams";
import { useNavigation } from "@magoot-sdk/hooks/useNavigation";
import { translate } from "@magoot-sdk/i18n/translate";
import { actionStudioSubscriptionRequest } from "@magoot-sdk/redux-modules/actions/studio/actionStudioSubscription";
import { actionUIToggleGlobalNotify } from "@magoot-sdk/redux-modules/actions/ui/actionUi";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import {
    selectorSubscriptionCanceled,
    selectorSubscriptionCurrentSubscription,
    selectorSubscriptionExpired,
    selectorSubscriptionExpiring,
    selectorSubscriptionTrial,
} from "@magoot-sdk/redux-modules/selectors/selectorsSubscription";
import { selectorSubscriptionModalVisible } from "@magoot-sdk/redux-modules/selectors/selectorsUI";
import { useDispatch } from "@magoot-sdk/redux-modules/utils/useDispatch";
import { Path, utilityGetUrlViewDashboard } from "@magoot-sdk/utils/Routes";
import { utilityFormatDate } from "@magoot-sdk/utils/time/formatDate";

import { ModalUpgrade } from "./partials/ModalUpgrade";
import { SubscriptionAlert } from "./partials/SubscriptionAlert";

interface Props {
    children: JSX.Element | JSX.Element[];
}

export const Subscription: FC<Props> = memo(({ children }: Props): JSX.Element => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const pathSplitted = pathname.split("/");
    const idStudio = parseInt(pathSplitted[1], 10);
    const navigateTo = useNavigation();
    const locale = useLocale();
    const showSubscriptionModal = useSelector(selectorSubscriptionModalVisible);
    const isSubscriptionExpiring = useSelector((store: TStore) =>
        selectorSubscriptionExpiring({ store, idStudio })
    );
    const isSubscriptionExpired = useSelector((store: TStore) =>
        selectorSubscriptionExpired({ store, idStudio })
    );
    const isSubscriptionTrial = useSelector((store: TStore) =>
        selectorSubscriptionTrial({ store, idStudio })
    );
    const isSubscriptionCanceled = useSelector((store: TStore) =>
        selectorSubscriptionCanceled({ store, idStudio })
    );

    const currentSubscription = useSelector((store: TStore) =>
        selectorSubscriptionCurrentSubscription({ store, idStudio })
    );

    const [isVisibleSubscriptionExpiring, setIsVisibleSubscriptionExpiring] =
        useState(isSubscriptionExpiring);
    const [isVisibleSubscriptionExpired, setIsVisibleSubscriptionExpired] =
        useState(isSubscriptionExpired);
    const [isVisibleSubscriptionTrial, setIsVisibleSubscriptionTrial] =
        useState(isSubscriptionTrial);
    const [isVisibleSubscriptionCanceled, setIsVisibleSubscriptionCanceled] =
        useState(isSubscriptionCanceled);

    const onSetIsVisibleSubscriptionExpiring = useCallback(() => {
        setIsVisibleSubscriptionExpiring(isSubscriptionExpiring);
    }, [isSubscriptionExpiring]);

    const onSetIsVisibleSubscriptionExpired = useCallback(() => {
        setIsVisibleSubscriptionExpired(isSubscriptionExpired);
    }, [isSubscriptionExpired]);

    const onSetIsVisibleSubscriptionTrial = useCallback(() => {
        setIsVisibleSubscriptionTrial(isSubscriptionTrial);
    }, [isSubscriptionTrial]);

    const onSetIsVisibleSubscriptionCanceled = useCallback(() => {
        setIsVisibleSubscriptionCanceled(isSubscriptionCanceled);
    }, [isSubscriptionCanceled]);

    const differenceDay = useMemo(() => {
        const differenceTime =
            new Date(currentSubscription?.dataScadenza).getTime() - new Date().getTime();
        const differenceString = (differenceTime / (1000 * 3600 * 24)).toString();

        return Math.round(+differenceString);
    }, [currentSubscription]);

    const differenceInDaysGTM = useMemo(() => {
        return differenceInDays(new Date(currentSubscription?.dataScadenza), new Date());
    }, [currentSubscription]);

    const avarageGTM = useMemo(() => {
        const avarage = differenceInDaysGTM / 2;
        const dateWithoutAvarage = new Date(currentSubscription?.dataScadenza);
        dateWithoutAvarage.setDate(new Date(currentSubscription?.dataScadenza).getDate() - avarage);

        return isEqual(dateWithoutAvarage, new Date());
    }, [differenceInDaysGTM]);

    const endTrialGTM = useMemo(() => {
        return isEqual(new Date(currentSubscription?.dataScadenza), new Date());
    }, [currentSubscription]);

    useEffect(() => {
        if (avarageGTM) {
            (window as any)?.dataLayer.push({
                event: "half_trial",
            });
        }

        if (endTrialGTM) {
            (window as any)?.dataLayer.push({
                event: "end_trial",
            });
        }
    }, [avarageGTM, endTrialGTM]);

    const dateCanceled =
        !!currentSubscription?.dataDisdetta && new Date(currentSubscription?.dataDisdetta);

    useEffect(() => {
        onSetIsVisibleSubscriptionExpiring();
        onSetIsVisibleSubscriptionExpired();
        onSetIsVisibleSubscriptionTrial();
        onSetIsVisibleSubscriptionCanceled();
    }, [
        dispatch,
        idStudio,
        onSetIsVisibleSubscriptionExpiring,
        onSetIsVisibleSubscriptionExpired,
        onSetIsVisibleSubscriptionTrial,
        onSetIsVisibleSubscriptionCanceled,
    ]);

    useEffect(() => {
        dispatch(actionUIToggleGlobalNotify(currentSubscription?.trial));
    }, [dispatch, currentSubscription]);

    useEffect(() => {
        if (!!idStudio && pathname !== Path.viewNotFound) {
            dispatch(actionStudioSubscriptionRequest(idStudio));
        }
    }, [dispatch, idStudio]);

    const handleSetIsVisibleSubscriptionTrial = (isVisible: boolean): void => {
        dispatch(actionUIToggleGlobalNotify(isVisible));
        setIsVisibleSubscriptionTrial(isVisible);
    };

    const handleSetIsVisibleSubscriptionExpiring = (isVisible: boolean): void => {
        dispatch(actionUIToggleGlobalNotify(isVisible));
        setIsVisibleSubscriptionExpiring(isVisible);
    };

    const handleSetIsVisibleSubscriptionExpired = (isVisible: boolean): void => {
        dispatch(actionUIToggleGlobalNotify(isVisible));
        setIsVisibleSubscriptionExpired(isVisible);
    };

    const handleSetIsVisibleSubscriptionCanceled = (isVisible: boolean): void => {
        dispatch(actionUIToggleGlobalNotify(isVisible));
        setIsVisibleSubscriptionCanceled(isVisible);
    };

    const onTrialClick = (): void => {
        const linkSubMenu = utilityGetUrlViewDashboard({
            idStudio,
            menuSelected: MenuSection.GestioneStudio,
            subMenuSelected: SubMenuSection.Abbonamento,
            sectionSubscription: SubscriptionSection.SelezionePiano,
        });

        navigateTo(linkSubMenu);
    };

    const onExpiringClick = (): void => {
        const linkSubMenu = utilityGetUrlViewDashboard({
            idStudio,
            menuSelected: MenuSection.GestioneStudio,
            subMenuSelected: SubMenuSection.Abbonamento,
        });

        navigateTo(linkSubMenu);
    };

    const onExpiredClick = (): void => {
        const linkSubMenu = utilityGetUrlViewDashboard({
            idStudio,
            menuSelected: MenuSection.GestioneStudio,
            subMenuSelected: SubMenuSection.Abbonamento,
            sectionSubscription: SubscriptionSection.SelezionePiano,
        });

        navigateTo(linkSubMenu);
    };

    const onCanceledClick = (): void => {
        const linkSubMenu = utilityGetUrlViewDashboard({
            idStudio,
            menuSelected: MenuSection.GestioneStudio,
            subMenuSelected: SubMenuSection.Abbonamento,
        });

        navigateTo(linkSubMenu);
    };

    return (
        <>
            {isVisibleSubscriptionTrial && !isSubscriptionExpired && !isSubscriptionExpiring && (
                <SubscriptionAlert
                    setIsVisible={handleSetIsVisibleSubscriptionTrial}
                    text={`${translate({
                        locale,
                        id: "general.label.subscription-expiring",
                    })} ${+differenceDay} giorni`}
                    buttonText={translate({ locale, id: "general.label.button-trial-version" })}
                    onClick={onTrialClick}
                />
            )}

            {isVisibleSubscriptionExpired && +differenceDay <= 0 && (
                <SubscriptionAlert
                    setIsVisible={handleSetIsVisibleSubscriptionExpired}
                    text={translate({ locale, id: "general.label.subscription-expired" })}
                    buttonText={translate({ locale, id: "general.label.subscription" })}
                    onClick={onExpiredClick}
                />
            )}

            {isVisibleSubscriptionExpiring &&
                +differenceDay > 0 &&
                !isVisibleSubscriptionExpired &&
                !isVisibleSubscriptionCanceled && (
                    <SubscriptionAlert
                        setIsVisible={handleSetIsVisibleSubscriptionExpiring}
                        text={`${translate({
                            locale,
                            id: "general.label.subscription-expiring",
                        })} ${+differenceDay} giorni`}
                        buttonText={translate({ locale, id: "general.label.subscription" })}
                        onClick={onExpiringClick}
                    />
                )}

            {isVisibleSubscriptionTrial &&
                isVisibleSubscriptionExpiring &&
                +differenceDay <= 0 &&
                !isVisibleSubscriptionExpired &&
                !isVisibleSubscriptionCanceled && (
                    <SubscriptionAlert
                        setIsVisible={handleSetIsVisibleSubscriptionExpiring}
                        text={`${
                            differenceDay === 0
                                ? translate({
                                      locale,
                                      id: "general.label.subscription-expiring.extra.2.day",
                                  })
                                : translate({
                                      locale,
                                      id: "general.label.subscription-expiring.extra.1.day",
                                  })
                        }`}
                        buttonText={translate({ locale, id: "general.label.subscription" })}
                        onClick={onExpiringClick}
                    />
                )}

            {isVisibleSubscriptionCanceled && !isVisibleSubscriptionExpired && (
                <SubscriptionAlert
                    setIsVisible={handleSetIsVisibleSubscriptionCanceled}
                    text={`${translate({
                        locale,
                        id: "general.label.subscription-canceled",
                    })} ${dateCanceled ? utilityFormatDate(dateCanceled) : ""}`}
                    buttonText={translate({ locale, id: "general.label.subscription" })}
                    onClick={onCanceledClick}
                />
            )}

            {showSubscriptionModal && <ModalUpgrade pathSplitted={pathSplitted} />}
            {children}
        </>
    );
});

Subscription.displayName = "Subscription";
