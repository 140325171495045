// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import React, { FC, memo, useState } from "react";

import { translate } from "@magoot-sdk/i18n/translate";
import { useOrderPermission } from "@magoot-sdk/redux-modules/hooks/order/useOrderPermission";
import { useSubscription } from "@magoot-sdk/redux-modules/hooks/subscription/useSubscription";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

import { UIButton } from "@components-ui/Button";

import { FormWork } from "./FormWork";

// #endregion

export const WorkCreate: FC = memo((): JSX.Element => {
    const locale = useLocale();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { hasValidPermission } = useOrderPermission();
    const { hasToUpgrade, onUpgrade } = useSubscription({ code: "WORKS" });

    const modalTitle = translate({ locale, id: "view-order.work.modal.title.create" });

    const onOpenModal = (): void => {
        if (hasToUpgrade) onUpgrade();
        else setIsModalVisible(true);
    };

    const onCloseModal = (): void => setIsModalVisible(false);

    return (
        <>
            {/* MODAL */}
            {isModalVisible && <FormWork onCloseModal={onCloseModal} modalTitle={modalTitle} />}
            {/* ROW */}
            <div className='flex items-center justify-between'>
                <p className='font-bold uppercase text-tundora-500'>
                    {translate({ locale, id: "general.label.performance-phases" })}
                </p>

                {hasValidPermission && (
                    <UIButton
                        icon={faPlus as IconProp}
                        dataCy='TO_DO'
                        onClick={onOpenModal}
                        variant='primary'
                        title='aggiungi nuova fase'
                        size='sm'
                        isCircle
                        fluid={false}
                    />
                )}
            </div>
        </>
    );
});
WorkCreate.displayName = "WorkCreate";
