import { TNoteType } from "../../../api/note/declarations";
import { Actions } from "../../utils/Actions";
import { TReducerOrders, TReducerOrdersActions } from "./declarations";

export const reducerOrders = (
    prevStore: TReducerOrders = {},
    action: TReducerOrdersActions
): TReducerOrders => {
    const newStore = { ...prevStore };
    let newCommitmentList;

    switch (action.type) {
        case Actions.orderListSuccess:
            return action.payload.items;
        case Actions.createOrderSuccess:
        case Actions.orderUpdateSuccess:
        case Actions.paperworkImportSuccess:
            newStore[action.payload.id] = action.payload;
            return newStore;
        case Actions.orderReadSuccess:
            newStore[action.payload?.id] = {
                ...newStore[action.payload?.id],
                dataUltimoAccesso: action.payload?.dataUltimoAccesso,
            };
            return newStore;
        case Actions.orderTeamSuccess:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                utenti: action.payload.utenti,
            };
            return newStore;
        case Actions.commitmentCreateSuccess:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                committenti: [
                    ...newStore[action.payload.idOrder].committenti,
                    action.payload.customer,
                ],
            };
            return newStore;
        case Actions.commitmentDeleteSuccess:
            newCommitmentList = newStore[action.payload.idOrder].committenti.filter(
                (commitment) => commitment.id !== action.payload.idCustomer
            );
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                committenti: newCommitmentList,
            };
            return newStore;
        case Actions.customerUpdateSuccess:
            newCommitmentList = newStore[action.payload.idOrder].committenti.map(
                (commitment): any => {
                    if (commitment.id === action.payload.customer.id)
                        return action.payload.customer;
                    return commitment;
                }
            );
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                committenti: newCommitmentList,
            };
            return newStore;
        case Actions.noteAddSuccess:
            if (action.payload.tipo === TNoteType.Commessa) {
                const idCommessa = action.payload.idOrder;
                newStore[idCommessa] = {
                    ...newStore[idCommessa],
                    numeroNote: newStore[idCommessa].numeroNote + 1,
                };
            }
            return newStore;
        case Actions.noteDeleteSuccess:
            if (action.payload.tipo === TNoteType.Commessa) {
                const idCommessa = action.payload.idOrder;
                newStore[idCommessa] = {
                    ...newStore[idCommessa],
                    numeroNote: newStore[idCommessa].numeroNote - 1,
                };
            }
            return newStore;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
