// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { FC, memo } from "react";

import { BgBadgeStyle, ColorBadgeStyle } from "@magoot-sdk/utils/helpers/getBadgeStyle";

import { UIIcon } from "@components-ui/Icon";

// #endregion

export interface Props {
    color?: ColorBadgeStyle;
    backgroundColor?: BgBadgeStyle;
    text: string;
    title?: string;
    icon?: IconProp;
    tooltipPosition?: "left" | "right" | "center";
    onRemoveTag?: () => void;
}

export const UIBadge: FC<Props> = memo(
    ({
        color = "secondaryDark",
        text,
        backgroundColor = "magootBadgeDefault",
        icon,
        onRemoveTag,
        tooltipPosition,
    }): JSX.Element => (
        <div
            className={`w-fit text-sm
                    ${tooltipPosition === "center" ? "tooltip" : ""}
                    ${tooltipPosition === "left" ? "tooltip tooltip-left" : ""}
                    ${tooltipPosition === "right" ? "tooltip tooltip-right" : ""}
                `}
            data-tip={text}
        >
            <div
                className={`badge space-x-4 p-8
                    ${backgroundColor === "primary" ? "badge-primary" : ""}
                    ${backgroundColor === "secondary" ? "badge-secondary" : ""}
                    ${backgroundColor === "magootBadgeDefault" ? "bg-magootBadgeDefault" : ""}
                    ${backgroundColor === "successLight" ? "bg-successLight" : ""}
                    ${backgroundColor === "secondaryDarkLight" ? "bg-secondaryDarkLight" : ""}
                    ${backgroundColor === "errorLight" ? "bg-errorLight" : ""}
                    ${backgroundColor === "warning" ? "bg-warningAlternative" : ""}
                    ${backgroundColor === "error" ? "bg-error" : ""}
                    ${icon ? "badge-lg" : "badge-sm "} 
                border-none`}
            >
                {!!icon && <UIIcon svgIcon={icon} onClick={onRemoveTag} />}
                <p
                    className={`text-xs font-medium
                            ${color === "primary" ? "text-primary" : ""}
                            ${color === "secondary" ? "text-secondary" : ""}
                            ${color === "success" ? "text-success" : ""}
                            ${color === "secondaryDark" ? "text-secondaryDark" : ""}
                            ${color === "errorDark" ? "text-errorDark" : ""}
                            ${color === "expiredText" ? "text-expiredText" : ""}
                            ${color === "warningBase" ? "text-warningBase" : ""}
                            ${color === "white" ? "text-white" : ""}
                        `}
                >
                    {text}
                </p>
            </div>
        </div>
    )
);

UIBadge.displayName = "UIBadge";
