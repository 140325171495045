import React, { FC, memo, useMemo } from "react";

import { translate } from "../../@magoot-sdk/i18n/translate";
import { useLocale } from "../../@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { TOrderUser } from "../../@magoot-sdk/redux-modules/reducers/reducerOrder/declarations";
import { TUser } from "../../@magoot-sdk/redux-modules/reducers/reducerUsers/declarations";

export interface Props {
    user: TUser | TOrderUser;
}

export const UserRole: FC<Props> = memo(({ user }): JSX.Element => {
    const locale = useLocale();

    const externalUser = useMemo(() => user.attivo && user.ruolo === 3 && user.stato === 1, [user]);

    const selectOptionsOutside = [
        { name: translate({ locale, id: "general.label.admin" }), value: 1 },
        { name: translate({ locale, id: "general.label.partner.indoor" }), value: 2 },
        { name: translate({ locale, id: "general.label.partner.outside" }), value: 3 },
    ];

    const selectOptionsInside = [
        { name: translate({ locale, id: "general.label.admin" }), value: 1 },
        { name: translate({ locale, id: "general.label.partner.indoor" }), value: 2 },
    ];

    return (
        <span className='text-sm text-secondary font-medium uppercase'>
            {user.ruolo > 0 && (
                <>
                    {externalUser
                        ? selectOptionsOutside.find((opt) => opt.value === user.ruolo)?.name
                        : selectOptionsInside.find((opt) => opt.value === user.ruolo)?.name}
                </>
            )}
            {user.ruolo === 0 && <>{translate({ locale, id: "general.label.owner" })}</>}
        </span>
    );
});
