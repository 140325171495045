interface Translations {
    "view.summary.compliments": string;
    "view.summary.first-order-created": string;
    "view.summary.prevision": string;
    "view.summary.cashed": string;
    "view.summary.costs": string;
    "view.summary.order-costs": string;
    "view.summary.order-end": string;
    "view.summary.add-address": string;
}

interface ViewSummary {
    it: Translations;
    en: Translations;
}

export const translationsViewSummary: ViewSummary = {
    it: {
        "view.summary.compliments": "Complimenti",
        "view.summary.first-order-created": "hai creato la tua prima commessa!",
        "view.summary.prevision": "Preventivo",
        "view.summary.cashed": "Incassato",
        "view.summary.costs": "Spese",
        "view.summary.order-costs": "Costi commessa",
        "view.summary.order-end": "Termina commessa",
        "view.summary.add-address": "Inserisci nuovo indirizzo su dati territoriali",
    },
    en: {
        "view.summary.compliments": "Compliments",
        "view.summary.first-order-created": "you have created your first order!",
        "view.summary.prevision": "Takings prevision",
        "view.summary.cashed": "Cashed",
        "view.summary.costs": "Costs",
        "view.summary.order-costs": "Order costs",
        "view.summary.order-end": "Ends order",
        "view.summary.add-address": "Enter new address on territorial data",
    },
};

export type TTranslationsViewSummary = keyof Translations;
