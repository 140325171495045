interface Translations {
    "view.task-inline.update.error": string;
    "view.task-list.to-do": string;
    "view.task-list.doing": string;
    "view.task-list.done": string;
    "view.task-list.add-to-do": string;
    "view.task-list.add-doing": string;
    "view.my-task-list": string;
    "view.task-list.add-done": string;
    "view.task-list.form.activity": string;
    "view.task-list.form.owner": string;
    "view.task-list.form.owner.select": string;
    "view.task-list.form.data-inizio": string;
    "view.task-list.form.expiration-date": string;
    "view.task-list.form.tooltip": string;
    "view.task-list.form.days-notice": string;
    "view.task-list.form.add-note": string;
    "view.task-list.modal.title.create": string;
    "view.task-list.modal.title.update": string;
    "view.task-list.modal.task-delete": string;
    "view.empty.action": string;
    "view.task-list.form.duration.hours": string;
    "view.task-list.form.duration.hours-short": string;
    "view.task-list.form.duration.minutes": string;
    "view.inspection.form.duration.hours": string;
    "view.inspection.form.duration.minutes": string;
}

interface ViewTaskList {
    it: Translations;
    en: Translations;
}

export const translationsViewTaskList: ViewTaskList = {
    it: {
        "view.task-inline.update.error": "Errore nella modifica",
        "view.task-list.to-do": "Da fare",
        "view.task-list.doing": "In corso",
        "view.task-list.done": "Terminate",
        "view.task-list.add-to-do": "Nessuna attività pianificata",
        "view.task-list.add-doing": "Nessuna attività in corso",
        "view.task-list.add-done": "Nessuna attività conclusa",
        "view.my-task-list": "Le mie attività",
        "view.task-list.form.activity": "Attività",
        "view.task-list.form.owner": "Incaricato",
        "view.task-list.form.owner.select":
            "Puoi selezionare solamente i membri che hanno i permessi in modalità scrittura. Per modificare i permessi vai alla sezione",
        "view.task-list.form.data-inizio": "Data inizio attività",
        "view.task-list.form.expiration-date": "Da fare entro",
        "view.task-list.form.tooltip": `La data "Da fare entro" non può superare la data di scadenza o di fine della fase`,
        "view.task-list.form.days-notice": "Giorni preavviso",
        "view.task-list.form.add-note": "Aggiungi nota",
        "view.task-list.modal.title.create": "Crea attività",
        "view.task-list.modal.title.update": "Modifica attività",
        "view.task-list.modal.task-delete": "Elimina attività",
        "view.empty.action": "Crea la tua prima attività",
        "view.task-list.form.duration.hours": "Tempo impiegato",
        "view.task-list.form.duration.hours-short": "Tempo impiegato (ore)",
        "view.task-list.form.duration.minutes": "Durata minuti",
        "view.inspection.form.duration.minutes": "(min)",
        "view.inspection.form.duration.hours": "Durata (ore)",
    },
    en: {
        "view.task-inline.update.error": "Errore nella modifica",
        "view.task-list.to-do": "To do",
        "view.task-list.doing": "Doing",
        "view.task-list.done": "Done",
        "view.task-list.add-to-do": "Add to do activity",
        "view.task-list.add-doing": "Add doing activity",
        "view.task-list.add-done": "Add done activity",
        "view.my-task-list": "Le mie attività",
        "view.task-list.form.activity": "Activity",
        "view.task-list.form.owner": "Owner",
        "view.task-list.form.owner.select":
            "Puoi selezionare solamente i membri che hanno i permessi in modalità scrittura. Per modificare i permessi vai alla sezione",
        "view.task-list.form.data-inizio": "Data inizio attività",
        "view.task-list.form.expiration-date": "To be done by",
        "view.task-list.form.tooltip": `La data "Da fare entro" non può superare la data di scadenza o di fine della fase`,
        "view.task-list.form.days-notice": "Days notice",
        "view.task-list.form.add-note": "Add note",
        "view.task-list.modal.title.create": "Create task",
        "view.task-list.modal.title.update": "Update task",
        "view.task-list.modal.task-delete": "Delete task",
        "view.empty.action": "Crea la tua prima attività",
        "view.task-list.form.duration.hours": "Durata ora",
        "view.task-list.form.duration.hours-short": "Durata (ore)",
        "view.task-list.form.duration.minutes": "Durata minuti",
        "view.inspection.form.duration.minutes": "Durata (min)",
        "view.inspection.form.duration.hours": "(ore)",
    },
};

export type TTranslationsViewTaskList = keyof Translations;
